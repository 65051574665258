@import "variables";
@import "mixins";

.spares-table {
    @include tableProperty;
}

.spares-scrollbar {
    height: calc(100vh - 180px);
    margin: 10px;
}

.spares-thead {
    @include tableHeadProperty;
}

.btn-add-spare {
    @include buttonProperty($blackColor, 30px, auto, 0, 14px, $whiteColor);
    margin-right: 10px;

    &:hover {
        color: $whiteColor;
        box-shadow: none;
        background-color: $blackColor;
    }

    &:focus {
        box-shadow: none;
    }

    h6 {
        font-size: 14px;
        margin-left: 2px;
    }
}


.row-img-spare {
    display: flex;
    align-items: center;
    cursor: pointer;

    .spare-img-box {
        position: relative;
        display: flex;
        // background-color: $inputBox_backgroundColor;
        background-image: radial-gradient($whiteColor, $whiteColor, $lightGray 90%);
        border: 1px solid $lightGray;
        border-radius: 5px;
        padding: 3px;
        width: 30px !important;
        height: 30px !important;

        .row-spare-images {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.spare-table-row-data .spare-edit-link {
    color: $greenBlueColor;
    font-size: $fz13 !important;
    padding: 0px 5px !important;
}

.spares-stocks-link{
    color: $navLinkColor;
    font-size: $fz13;
    padding: 0px 5px !important;
}

.view-sparedetails-model {
    .modal-content {
        width: 800px;
        position: relative;
        right: 120px;
    }
}

.main-image-name-code {
    
    .upload-main-img-n-btn {
        position: relative;

        .spare-main-image {
            position: relative;
            text-align: center;
            justify-items: center;
            padding: 5px;
            border-radius: 6px;
            width: 100%;
            height: 120px;
            // background-color: $lightGray;
            background-image: radial-gradient($whiteColor, $whiteColor, #f6f6f6 70%);

            .spare-big-image {
                width: 90%;
                height: 100%;
            }
        }

        p {
            color: $whiteColor;
            font-size: $fz10 !important;
        }

    }
}

.spare-image-box {
    position: relative;
    display: flex;
    margin: 8px;
    width: 100px !important;
    height: 100px !important;
    align-items: center;
    justify-items: center;
    padding: 5px;
    background-image: radial-gradient($whiteColor, $whiteColor, $lightGray 70%);
    // background-color: $lightGray;

    .spare-images {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.spare-details-model {
    padding: 20px;

    .spare-detail-left-part {
        .spare-big-img {
            position: relative;
            width: 100%;
            height: 320px;
            margin-bottom: 20px;
            transition: transform .2s;
            padding: 15px;
            border-radius: 10px;
            box-shadow: $chatBoxShadow;
            // background-image: radial-gradient($whiteColor, $whiteColor, $lightGray 90%);
            &:hover {
                -ms-transform: scale(1.05);
                /* IE 9 */
                -webkit-transform: scale(1.05);
                /* Safari 3-8 */
                transform: scale(1.05);
            }

            .spare-img {
                // background-color: $table_titleTop_backgroundColor;
                // background-image: radial-gradient($whiteColor, $whiteColor, $lightGray 90%);
                // border-radius: 10px;
                // padding: 10px;
                width: 100%;
                height: 300px;
                object-fit: contain;
            }

        }

        .carousel-sliding-part {
            // background-color: $lightGray;
            box-shadow: $chatBoxShadow;
            border-radius: 10px;
            padding: 8px 10px;
            .spare-small-images-row {
                width: 100%;
                height: auto;
                display: flex;
                justify-content: space-between;

                .spare-small-image {
                    position: relative;
                    width: 80px;
                    height: 80px;
                    transition: transform .2s;
                    margin: 0 auto;
                    border-radius: 10px;
                    padding: 5px;
                    border: 1px solid gray;
                    background-color: $whiteColor;
                    // &:hover {
                    //     -ms-transform: scale(1.1);
                    //     /* IE 9 */
                    //     -webkit-transform: scale(1.1);
                    //     /* Safari 3-8 */
                    //     transform: scale(1.1);
                    // }
 
                    .spare-img {
                        // background-color: $table_titleTop_backgroundColor;
                        // background-image: radial-gradient($whiteColor, $whiteColor, $lightGray 70%);
                        width: 100%;
                        height: 100%;
                        // padding: 5px;
                        // object-fit: contain;
                    }
                }
            }
        }
                
    }

    .spare-detail-right-part {
        .spare-name {
            margin-bottom: 20px;

            h5 {
                font-size: $fz18;
                font-weight: bold;
            }
        }

        .spare-details-by-row {
            padding-bottom: 10px;
            border-bottom: 2px solid $lightGray;

            .spare-row {
                margin-bottom: 10px;
                font-size: $fz13;
                width: 100%;
                display: flex;

                .pdh {
                    width: 165px !important;
                    max-width: 165px;
                    font-weight: $fwt600;
                    color: $greenBlueColor;
                }

                .pdd {
                    color: $darkgrayColor;
                    width: 200px !important;
                    max-width: 200px;
                }
            }
        }

        .spare-description {
            margin: 10px 0;

            .description-instruction {
                .description-instruction-nav-tab {
                    .nav-link {
                        color: $darkgrayColor;

                        &.active {
                            color: $greenBlueColor;
                        }
                    }
                }

                .tab-content {
                    margin-top: 10px;

                    p {
                        word-break: break-word;
                        text-align: justify;
                    }
                }
            }
        }
    }

}

/* ADD & EDIT CLIENT MODEL START */
.main_model {
    background-color: #b8b8b896;
    padding: 10px;
    z-index: 9999;
}

.add-spare-input {
    border-radius: 5px;
    font-size: 13px;
    background-color: rgba(250, 250, 250, 0.8549019608);
}

.add-edit-spare-model {
    .modal-content {
        width: 90% !important;
        margin: auto;
    }

    .add-edit-spare {
        padding: 15px 20px;

        .btns-save-cancel {
            display: inline-flex;
            margin-bottom: 10px;
            align-items: center;
            text-align: center;
            justify-content: center;
        }

        .btn-save-up {
            background-color: $greenBlueColor;
            color: $whiteColor;
            border-radius: 5px;
            padding: 5px 15px;
            height: 30px;

            p {
                font-size: 15px;
                font-weight: 400;
                position: relative;
                bottom: 3px;
            }

            &:hover {
                color: $whiteColor;
            }
        }

        .btn-cancel {
            background-color: $grayColor;
            color: $whiteColor;
            border-radius: 5px;
            padding: 5px 15px;
            margin-right: 10px;
            height: 30px;

            p {
                font-size: 15px;
                font-weight: 400;
                position: relative;
                bottom: 3px;
            }

            &:hover {
                color: $whiteColor;
            }
        }
    }
}

.add-edit-spare-heading {
    @include modelHeader;
}

.form-control:focus {
    box-shadow: none;
    border-color: #b3b3b3;
}

.img-upload-btn {
    margin: 5px auto;
    text-align: center;
    .btn-add-spare-img {
        padding: 5px 8px;
        color: $blackColor;
        text-align: center;
        cursor: pointer;
        font-size: $fz12;
        background-color: $darkOrangeColor;
        width: 100%;
    }
}

.btn-add-spare-gallery-img {
    background-color: $modelhfbackgroundColor;
    box-shadow: $pageDropdown_boxShadow !important;
    color: $blackColor;
    text-align: center;
    cursor: pointer;
    font-size: $fz13;
    width: 100%;
    &:hover{
        background-color: $lightGreen;
        // box-shadow: $card_BoxShadow;
    }
}


.view-spares-model {
    .modal-content {
        width: 800px;
        position: relative;
        right: 120px;
    }
}


/* SCROLL BAR CSS with Styling START */
/* width */
.spares-scrollbar::-webkit-scrollbar {
    height: 5px;
    width: 5px;
}

/* Track */
.spares-scrollbar::-webkit-scrollbar-track {
    background: $scrollerBackgroundColor;
}

/* Handle */
.spares-scrollbar::-webkit-scrollbar-thumb {
    background: $scrollerThumbColor;
    border-radius: 0px;
}

/* Handle on hover */
.spares-scrollbar:-webkit-scrollbar-thumb:hover {
    background: rgb(102, 102, 102);
}

/* SCROLL BAR CSS with Styling END */