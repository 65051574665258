@import "variables";
@import "mixins";

.clients-table {
    @include tableProperty;
}

.clients-scroll {
    height: calc(100vh - 210px);
    // height: 65vh;
    margin: 10px;
}

.clients-list-thead {
    @include tableHeadProperty;
}

.clients-table{
    .client-search-input{
        position: relative;
        .fa-search {
            position: absolute;
            top: 10px;
            right: 10px;
            color: lightgray;
            opacity: 0.8;
        }
        input[type=search] {
            width: auto;
            padding-right: 25px;
        }
    }
} 
.input-search-client{
    width: 100% !important;
    line-height: 16px !important;
}

.main-logo-image {
    position: relative;
    display: flex;
    width: 100% !important;
    align-items: center;
    justify-items: center;
    padding: 10px;
    border-radius: 4px;
    min-height: 180px;
    max-height: 180px;
    background-image: radial-gradient($whiteColor, $whiteColor, $lightGray 70%);
    .product-images {
        width: 100%;
        height: 155px;
        object-fit: fill;
    }
}


.btn-add-client {
    @include buttonProperty($blackColor, 30px, auto, 0, 14px, $whiteColor);
    margin-right: 10px;
    &:hover {
        color: $whiteColor;
        box-shadow: none;
        background-color: $blackColor;
    }
    &:focus {
        box-shadow: none;
    }
    h6 {
        font-size: $fz14;
        padding-left: 5px;
    }
}

.client-list-row-data {
    // position: relative;
    .client-edit-link {
        color: $greenBlueColor;
        font-size: $fz13;        
        padding: 5px;
        line-height: 1;
        // i{
        //     padding-right: 3px;
        // }
    }
    .client-history-link{
        color: $greenBlueColor;
        font-size: $fz13;
        padding: 5px;
        line-height: 1;
    }
    .client-asign-btn{
        padding: 5px;
        line-height: 1;
        width: 40%;
        img {
            width: 100%;
        }
    }
    .client-location {
        max-width: 250px;        
    }
    .client-email, .client-tagged {
        color: red;
    }
} 

.re-tag-count{
    color: $darkOrangeColor;
    .fa-thumb-tack{
        font-size: $fz10;
    }
}

.view-mapped-user{
    .fa{
        font-size: $fz14;
        color: $greenBlueColor;
        padding-left: 5px;
    }
}

.mappedto-users-model{
    .modal-content{
        width: 550px !important;
    }
}

/* ADD & EDIT CLIENT MODEL START */
.main_model {
    background-color: $whiteColor;
    padding: 10px;
    z-index: 9999;
}

.add-edit-clients-model .modal-content {
    width: 95% !important;    
    // margin-left: 50px;
}

.add-edit-client {
    padding: 10px;
    &.edit-client{
        padding: 10px;
    }
    .form-select{
        padding: .375rem 2.25rem .375rem .75rem;
    }
    .location{
        min-height: 60px;
    }
}
.enable-status{
    margin-left: 10px;
}
.add-edit-client-heading {
    @include modelHeader
}

.enable-radio-client {
    position: relative;
    vertical-align: middle;
    align-items: center;
}

.enable-radio-status {
    margin-left: 10px;
}

.btns-save-cancel {
    @include modelFooter;
}

.client-location-by-map{
    position: relative;
    .bi-geo-alt{
        position: absolute;
        right: 20px;
        bottom: 5px;
    }
}

/* SCROLL BAR CSS with Styling START */
/* width */
.clients-scroll::-webkit-scrollbar {
    height: 5px;
    width: 5px;
}

/* Track */
.clients-scroll::-webkit-scrollbar-track {
    background: $scrollerBackgroundColor;
}

/* Handle */
.clients-scroll::-webkit-scrollbar-thumb {
    background: $scrollerThumbColor;
    border-radius: 0px;
}

/* Handle on hover */
.clients-scroll:-webkit-scrollbar-thumb:hover {
    background: rgb(102, 102, 102);
}

.company-name-head {
    min-width: 230px !important;
}


.client-table td:first-child{
    position: sticky;
    left: 0;
    // z-index: 1;
}
.client-table th:first-child{
    top: 0 !important;
    z-index: 2;
    position: sticky;
    left: 0;
}

/* SCROLL BAR CSS with Styling END */