@import "variables";
@import "mixins";

.beta-tag {
    padding: 10px 20px;
    background: $darkOrangeColor;
    position: absolute;
    top: 19px;
    left: -65px;
    transform: rotate(-45deg);
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;

    h6 {
        color: $whiteColor;
    }
}

.beta-tag-trail {
    padding: 6px 20px;
    background: $darkOrangeColor;
    position: absolute;
    top: 12px;
    left: -72px;
    transform: rotate(-45deg);
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;

    h6 {
        color: $whiteColor;
        font-size: 12px;
        font-weight: 700;
    }
}

.login-page-body {
    background: $whiteColor;
    background-image: url("../../src/images/Login_Page/sb-bg.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 2% 16%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    // height: 100vh;
    height: 100vh;
    overflow: auto;

    .travelize-salesbee-logo {
        width: 400px;
        margin: 50px auto;
    }
}

@media only screen and (min-width: 1550px) {
    .login-page-body {
        height: 100vh;
    }

    .sb-form-section {
        width: 80%;
    }

    .sb-login-bgimg,
    .login-box {
        height: 550px;
    }
}

.sb-form-section {
    width: 90%;
}
.sb-Acess-section {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 60px 0px;
    img{
        width: 55%;
    }
}

.sb-login-logo-div {
    display: flex;
    justify-content: flex-start;
    text-align: left;
    padding-bottom: 16px;
    .sb-login-logo{
        width: 60% !important;
    }
}

.sb-login-row {
    background: $whiteColor !important;
    box-shadow: 0px 0px 8px -1.9px $lightGraphGreen;
    width: 100%;
    padding: 5px 30px !important;
}

.travelize-log,
.devider {
    display: flex;
    justify-content: center;
    align-items: center;
}

.devider {
    padding: 5px 0px;
}

.v1 {
    border-left: 2px solid $lightGraphGreen;
    height: 120px;
}

.sb-trv-logo {
    width: 50%;
}

.col-left {
    padding: 2% 1% 5% 5%;
}

.col-right {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2% 1%;
}

.login-page {
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: relative;
    width: 100%;
    padding: 5px 10px;
    // height: 100%;
}

.sb-login-bgimg {
    display: flex;
    justify-content: center;
    align-items: center;
    // height: 450px;
    width: 100%;
}

.sb-signin-text {
    padding-top: 1%;
}

.sb-forgot-text {
    padding-top: 15%;
}

.login-head-text {
    font-size: $fz25;
    font-weight: $fwt600;
    color: $topbarColor;
    padding: 5px 0px;
    // margin-bottom: 30px;
}

// .login-box {
//     width: 60%;
//     padding: 0px 20px;
//     // height: 100%;
//     height: 450px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     // background: rgba(255, 255, 255, 0.1);
//     border: 2px solid $darkOrangeColor;
//     // box-shadow: 2px 2px 2px $darkOrangeColor;
//     padding: 20px 30px;
//     -moz-border-radius: 6px;
//     -webkit-border-radius: 6px;
//     border-radius: 6px;
//     margin: 10px;
//     text-align: center;

.form-control {
    font-size: $fz13;
    // border-radius: 4px !important;
    background: $whiteColor !important;
    border: none;
    box-shadow: 0px 0px 6px -1.4px $lightGraphGreen !important;
    padding: 6px 15px;
    color: $topbarColor;
    line-height: 20px;
    height: auto;

    &.login-email,
    &.login-password {
        margin: 20px auto;
        border-radius: 4px !important;
        padding: 10px 15px;
        color: $topbarColor;
        background-color: $whiteColor !important;
        box-shadow: 0px 0px 6px -1.9px $lightGraphGreen !important;
        // box-shadow: 0px 0px 3px 0.6px $lightGraphGreen !important;
        border: none !important;
    }

    &:focus {
        box-shadow: 0px 0px 6px -0.1px $lightGraphGreen !important;
    }
}

.forget-pass-login-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // padding-top: 20px;
}

.forgot-pass-text {
    text-decoration: none;
    font-size: $fz13;
    color: #0a58ca;
    font-weight: 800;

    &:focus {
        text-decoration: none;
        box-shadow: none;
    }

    &:hover {
        text-decoration: underline;
    }
}

.login-signup-text {
    color: $greenBlueColor;

    &:hover {
        color: $greenBlueColor;
    }
}

.btn-login-button {
    background-color: $greenBlueColor;
    border-radius: 0px;
    font-size: $fz14;
    font-weight: 800;
    padding: 5px 10px;
    color: $whiteColor;
    margin-bottom: 0px;
    // min-width: 150px;
    min-width: 100%;
    border: none;

    &:hover {
        color: $whiteColor;
        background-color: $darkOrangeColor;
    }

    &:focus {
        background-color: $greenBlueColor;
    }

    &:focus {
        box-shadow: none;
    }
}

// }

.pass-hide-show {
    float: right;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 17px;
    color: $topbarColor;
    cursor: pointer;
}

.password-input {
    display: block;
    position: relative;
}


.login-msg {
    font-size: $fz14;

    &.success {
        color: $statusGreen
    }

    &.false {
        color: $dangerColor;
    }
}


.subscription-page-body {
    text-align: center;
    align-items: center;
    background: $mainBody_backgroundImg left top repeat $nav_back_Graycolor;
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: auto;

    .salesbeebasiclogo {
        width: 20%;
        margin: 30px auto;
    }
}

.contact-input {
    display: flex;
}

.sub-inputs {
    margin: 20px auto !important;
    .form-control{
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0.25rem;
        border-bottom-left-radius:0 !important;
        border-bottom-right-radius: 0.25rem;
    }
    .form-select {
        padding: 10px 15px !important;
        display: block;
        border: none !important;
        box-shadow: 0px 0px 6px -1.4px $lightGraphGreen !important;
        padding: 0.375rem 2.25rem 0.375rem 0.75rem;
        -moz-padding-start: calc(0.75rem - 3px);
        font-size: $fz14;
        font-weight: 400;
        line-height: 1.5;
        color: $topbarColor;
        background-color: $whiteColor !important;
        border: none;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0 !important;
        border-bottom-left-radius: 0.25rem;
        border-bottom-right-radius: 0 !important;
        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        &.cntry-code {
            width: 35% !important;

            &:focus {
                border: none !important;
                box-shadow: 0px 0px 6px -0.2px $lightGraphGreen !important;
            }
        }
    }
}

.btn-submit-button {
    background-color: $greenBlueColor;
    border: none;
    border-radius: 5px;
    font-size: $fz14;
    padding: 10px 10px;
    color: $whiteColor;
    margin: 10px auto;
    width: 100%;

    &:hover {
        color: $whiteColor;
        background-color: $greenBlueColor;
        box-shadow: none;
    }
}

.subscription-res-message {
    .alert {
        padding: 0.5rem 0.5rem;
        font-size: $fz14;
        font-weight: $fwt400;
        text-transform: capitalize;
        border: none;
        margin-bottom: 0px;
        opacity: 1;
        animation-name: visibility;
        animation-duration: 10s;
    }

    @keyframes visibility {
        from {
            opacity: 1;
        }

        to {
            opacity: 0;
        }
    }
}

@media (max-width: 576px) {
    .login-box {
        width: auto !important;
        display: block;
        align-items: center;
    }

    .travelize-salesbee-logo {
        width: 60% !important;
        margin: 30px auto;
    }

    .subscription-box {
        width: auto !important;
        display: block;
        align-items: center;
    }

    .salesbeebasiclogo {
        width: 60% !important;
        margin: 30px auto;
    }

}

// css for captcha
.custom-recaptcha {
    /* Add your custom styles here */
    // For example:
    width: 300px;
    margin: 0 auto;
    iframe{
        // height: 60px !important;
        // transform: scale(0.9 0) !important;
    }
    .rc-anchor-normal{
        height: 55px !important;
    }
  }
// css for captcha