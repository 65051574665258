@import "../styles/variables";
@import "../styles/mixins";

@media screen and (max-width: 1536px) { 
    .card-head, .td-user-location {
        font-size: $fz14 !important;
    }
    .grap-imag {
        width: 100% !important;
    }
    .grapg-skeleton {
        height: 40px !important;
      }

}

@media screen and (max-width: 1366px) {
    .card-dashboard {
        .card-body {
            min-height: 80px !important;
            max-height: 80px !important;
        }
    }

    .sort-div {
        .form-control {
            &.activity-search {
                    line-height: 25px;
                }
        }
    }

    .card-imag {
        width: 100% !important;
    }

    .map-element {
        height: 450px - 0 !important;
    }

    .map-conrtainer {
        min-height: 450px !important;
        max-height: 450px !important;
    }

    .user-activity-data-map {
        min-height: auto !important;
        max-height: auto !important;
    }

    .user-activity-data-col {
        min-height: 360px !important;
        max-height: 360px !important;
    }

    .sb-grap-text p,
    .client-text,
    .td-user-location,
    .card-head, .sort-left-dash,
    .selct-drop-by-dash,
    .act-heading,
    .refresh-activity,
    .select-by-dropdown,
    .sb-db-tbody td,
    .table-user-image h6 {
        font-size: 12px !important;
    }

    .user-activity-images p,
    .sb-db-table-th {
        font-size: $fz11 !important;
    }

    .sb-grap-text {
        line-height: 16px !important;
    }

    .act-num {
        font-size: $fz14 !important;
    }

    .user-list-top-head {
        padding: 8px !important;
    }

    .sch-meeting-head img,
    .direct-visit-head img {
        width: 12px !important;
        height: 12px !important;
    }

    .table-data-user-img {
        width: 40px !important;
        height: 40px !important;

    }

    .activity-icons {
        width: 23px !important;
        height: 23px !important;

        .fa,
        .fas {
            font-size: $fz13;
        }
    }

    .sb-card-title {
        font-size: 14px !important;
    }

    .custom-table-header tr th {
        font-size: $fz13 !important;
    }

    .custom-table-body tr td {
        font-size: $fz12 !important;
    }

    .sb-dashboard-table {

        .css-y8ay40-MuiTableCell-root {
            font-size: 13px;
        }
    }

    .custom-apex {
        height: 225px !important;
        min-height: 200px !important;
    }

    .custom-apex .apexcharts-canvas {
        height: 245px !important;
    }

    .user-table .react-select-container-list {
        min-width: 130px !important;
    }

    .react-select-container-list {
        min-width: 150px !important;
    }
    .grap-imag {
        width: 100% !important;
    }
    .grapg-skeleton {
        height: 30px !important;
      }
}


@media screen and (max-width: 1024px) {
    .sb-grap-text p, .client-text, .td-user-location, .card-head, .sort-left-dash, .selct-drop-by-dash, .act-heading, .refresh-activity, .select-by-dropdown, .sb-db-tbody td, .table-user-image h6 {
        font-size: $fz11 !important;
    }
    .grap-box{
        width: 6px !important;
        height: 6px !important;
    }
    .sb-grap-text {
        line-height: 14px !important;
    }
    .meeting-card, .attend-card{
        padding: 5px !important;
        margin: 0px !important;
    }
    .card-dashboard, .sb-data-col {
        padding: 7px 7px !important;
    }
    .card-dashboard .card-body {
        min-height: 65px !important;
        max-height: 65px !important;
    }
    .table-div-dashboard .ord-comp-meet-claim-data .title-n-data .data-title {
        font-size: 12px !important;
    }
    .table-div-dashboard .ord-comp-meet-claim-data .title-n-data .data-value,
    .table-div-dashboard .ord-comp-meet-claim-data .title-n-data .data-value i {
        font-size: 15px !important;
    }
    .table-div-dashboard .ord-comp-meet-claim-data .title-n-data {
        min-height: 65px !important;
        max-height: 65px !important;
    }
    .user-activity-data-col {
        min-height: 250px !important;
        max-height: 250px !important;
    }
    .grapg-skeleton {
        height: 30px !important;
      }

      .sort-div .form-control.activity-search {
        width: 130px !important;
        line-height: 22px !important;
      }
      .meeting-table-header {
        padding: 4px 5px 4px 5px !important;
      }
      .table-div-dashboard {
        padding: 10px !important;
      }
      .table-div-dashboard.third-row-div {
        height: 300px !important;
      }
      .user-activity-data-col .user-activity-rowdata {
        min-height: 550px !important;
        max-height: 550px !important;
      }
}
