@import "variables";
@import "mixins";


.department-table {
    @include tableProperty;
}

.department-scrollbar {
    height: calc(100vh - 200px);
    margin: 10px;
}

.department-thead {
    @include tableHeadProperty;
}


.btn-add-department {
    @include buttonProperty($greenBlueColor, 30px, auto, 0, 14px, $whiteColor);
    margin-right: 10px;

    // flex: none;
    &:hover {
        color: $whiteColor;
        box-shadow: none;
        background-color: $greenBlueColor;
    }

    &:focus {
        box-shadow: none;
    }

    h6 {
        font-size: $fz14;
        margin-left: 2px;
    }
}


.department-table-row-data .department-edit-link {
    color: $greenBlueColor;
    font-size: $fz13;
}


/* ADD & EDIT CLIENT MODEL START */
.main_model {
    background-color: $whiteColor;
    padding: 10px;
    z-index: 9999;
}

.add-basefare-input {
    border-radius: 5px;
    font-size: $fz13;
    background-color: rgba(250, 250, 250, 0.8549019608);
}

.add-edit-basefare-model {
    .modal-content {
        width: 80% !important;
        margin-left: 50px;
    }

    .add-edit-basefare {
        padding: 10px 20px;

        .btns-save-cancel {
            display: inline-flex;
            margin-bottom: 10px;
            align-items: center;
            text-align: center;
            justify-content: center;

            p {
                font-size: $fz15;
                font-weight: $fwt400;
                position: relative;
                bottom: 3px;
            }

            &:hover {
                color: $whiteColor;
            }
        }

        .btn-save-up {
            background-color: $greenBlueColor;
            color: $whiteColor;
            border-radius: 5px;
            padding: 5px 15px;
            height: 30px;

            p {
                font-size: $fz15;
                font-weight: $fwt400;
                position: relative;
                bottom: 3px;
            }

            &:hover {
                color: $whiteColor;
            }
        }

        .btn-cancel {
            background-color: $grayColor;
            color: $whiteColor;
            border-radius: 5px;
            padding: 5px 15px;
            margin-right: 10px;
            height: 30px;

            p {
                font-size: $fz15;
                font-weight: $fwt400;
                position: relative;
                bottom: 3px;
            }

            &:hover {
                color: $whiteColor;
            }
        }
    }
}

.add-edit-basefare-heading {
    @include modelHeader;
}

/* SCROLL BAR CSS with Styling START */
/* width */
.department-scrollbar::-webkit-scrollbar {
    height: 5px;
    width: 5px;
}

/* Track */
.department-scrollbar::-webkit-scrollbar-track {
    background: $scrollerBackgroundColor;
}

/* Handle */
.department-scrollbar::-webkit-scrollbar-thumb {
    background: $scrollerThumbColor;
    border-radius: 0px;
}

/* Handle on hover */
.department-scrollbar:-webkit-scrollbar-thumb:hover {
    background: rgb(102, 102, 102);
}

/* SCROLL BAR CSS with Styling END */