
// *----- COLOR VARIABLES ------*//

// $mainBody_backgroundImg: url(../images/bg.png);
$defaultLightGreeen: #c5c2ba;
$mainBody_backgroundImg: #ebf3f1;
$greenBlueColor: #04A96D;
$lightGraphGreen: #74d9b4;
$paleGraphGreen: #aec3bb;
$lightGraphOrange: #f3c855;
$lightPaleOrange: #ffb900a8;

// $greenBlueColor: #613192;
$darkOrangeColor: #FFB900;
$OrangeColor: #ff7b00;
$OrangeRed: orangered;
$statusOrange: rgb(255, 101, 83);
$statusGreen: #4ba83fea;
$statusYellow: #ffbb00e1;
$lightYellow: #fcefcab3;
$lightGreen: #e5fafab7;
$lightBlue: #dde9f9cc;
$darkYellow: #be8b01;
$darkGreen: #015a57;
$darkBlue: #004c8aea;
$skyBlue: #02b6e4;
$blackColor: #000000;
$textHeadColor: #7c7c7c;
$topbarColor: #76797e;
$inputFieldback_color:#fafafada;
$inputBackground: rgba(250, 250, 250, 0.8549019608);
$whiteColor: #fff;
$paleWhite: #f5f5f5;
$lightGreenTheme: #ecfff8;
$nav_back_Graycolor: #1b1e24;
$nav_link_Textcolor: #8b91a0;
$table_titleTop_backgroundColor: #f6faf9;
$table_titleTop_borderBottom: 2px solid #5c5c5c26;
$tablebody_backgroundColorY: #fbfbfb;
$tableHead_backgroundColor: #323232;
$modelhfbackgroundColor: #EDFCF9;
$modelhfborderColor: #9ce1d4;
$inputBox_backgroundColor: #e9fcf5;
$tableHead_text_Color: #566E5B;
$tableRow_borderBottom: #9ce1d4;
$grayColor: #919191;
$darkgrayColor: #5f5f5f;
$formLabelColor :#2e2e2e;
$lightGray:#e4e4e4;
$navLinkColor: #001988;
$scrollerBackgroundColor: #a0a0a079; 
$scrollerThumbColor: #6969694f;
$warningColor : #ffcc00;
$paleOrange: #f7ce638a;
$danderColor : #cc3300;
$dangerColor : #f80000;
$successColor : #339900;
$borderGreen: #7ce5aa;
$card_background: linear-gradient(90deg, rgba(4,169,109,1) 0%, rgba(54,196,145,1) 35%, rgba(136,222,191,1) 100%);
$card_BoxShadow: 2px 2px 5px 1.1px #7ce5aa;
$pageDropdown_boxShadow: 0px 0px 6px -1.4px #7ce5aa !important;
$table_BoxShadow: 0px 0px 8px -1.9px #7ce5aa;
$cardBodyBoxShadow: 0 0 6px -1.9px #7ce5aa;
$selectBoxShadow: 0px 0px 6px -0.1px #7ce5aa;
$projectBoxShadow :0px 0px 6px 1px rgb(0 0 0 / 0.2);
$chatBoxShadow :0px 0px 3px 2px rgba(132, 132, 132, 0.2);
$lightParrotGreen: rgb(214 240 214 / 61%);
$paleGreen : rgb(196 214 194 / 79%);
$paleBlue:rgb(203 224 226 / 79%);
$palelightGreen: #e9f7f2;
$paleYellow : #fdfaeb;
$lightRed: rgba(248, 211, 211, 0.795);
$paleblueGreen : #017470;
$paleRed : rgb(227, 85, 85);

$activUserBackground: #ecfbfb5c;

$approvedClaimColor: #56688A;
$approvedClaimBackground: #f4fffb;

$filterShadow: 0px -3px 3px -2px rgba(4, 169, 109, 0.318),
0px 3px 3px -2px rgba(4, 169, 109, 0.318);

$paleGray: #c2c2c2;

$grayBoxShadowColor: #c0bfbf;
$brandGradient: linear-gradient(90deg, #00FF00, #FFA500, #FF0000);

// status colors
$pendStatusColor: #fa8b0c;
$pendStatusBackground: #fa8b0c10;

$followStatusColor: #ffb900;
$followStatusBackground: #ffeec091;

$compStatusColor: #01b81a;
$compStatusBackground: #01b81a10;

$cancStatusColor: #f44336;
$cancStatusBackground: #f443363b;

$expirStatusColor: #FF5733;
$expirStatusBackground: #ff573326;

// Tooltip
$toolTipBackground: #ffdfba;

// *----- FONT SIZE & WEIGHT ------*//

$tableHead_textSize: 15px;
$tableData_headTextSize: 0.9rem;
$tableHead_fontWeight: 500;

$fwt300: 300;
$fwt400: 400;
$fwt500:500;
$fwt600: 600;
$fwt700:700;
$fwt800: 800;

$fz6 : 6px;
$fz8 : 8px;
$fz10 : 10px;
$fz11 : 11px;
$fz12 : 12px;
$fz13 : 13px;
$fz14 : 14px;
$fz15 : 15px;
$fz16 : 16px;
$fz18 : 18px;
$fz20 : 20px;
$fz22 : 22px;
$fz24 : 24px;
$fz25 : 25px;
$fz26 : 26px;
$fz28 : 28px;
$fz30 : 30px;
$fz32 : 32px;
$fz34 : 34px;
$fz35 : 35px;
$fz36 : 36px;


