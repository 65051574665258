@import "variables";
@import "mixins";


.roles-table {
    @include tableProperty;
}

.roles-scrollbar {
    height: calc(100vh - 200px);
    margin: 10px;
}

.roles-thead {
    @include tableHeadProperty;
}


.btn-add-roles {
    @include buttonProperty($greenBlueColor, 30px, auto, 0, 14px, $whiteColor);
    margin-right: 10px;

    // flex: none;
    &:hover {
        color: $whiteColor;
        box-shadow: none;
        background-color: $greenBlueColor;
    }

    &:focus {
        box-shadow: none;
    }

    h6 {
        font-size: $fz14;
        margin-left: 2px;
    }
}


.roles-table-row-data .roles-edit-link {
    color: $greenBlueColor;
    font-size: $fz13;
}



/* SCROLL BAR CSS with Styling START */
/* width */
.roles-scrollbar::-webkit-scrollbar {
    height: 5px;
    width: 5px;
}

/* Track */
.roles-scrollbar::-webkit-scrollbar-track {
    background: $scrollerBackgroundColor;
}

/* Handle */
.roles-scrollbar::-webkit-scrollbar-thumb {
    background: $scrollerThumbColor;
    border-radius: 0px;
}

/* Handle on hover */
.roles-scrollbar:-webkit-scrollbar-thumb:hover {
    background: rgb(102, 102, 102);
}

/* SCROLL BAR CSS with Styling END */