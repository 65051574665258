@import './mixins';
@import './variables';

.order-row {
    cursor: pointer;
    &:hover{
        background-color: $formLabelColor !important;
    }
}

.order-details-row {
    align-items: flex-start !important;
    .order-detail-list{
        list-style-type: none;
        padding: 0 !important;
        li {
            display: flex;
            align-items: center;
            padding: 2px 0px;
            .span1{
                width: 30%;
            }
            .span2{
                width: 70%;
            }
        }
    }
}
.company-logo-div{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.total-count-row td{
    border-top: 1px solid $lightGraphGreen !important;
    padding: 10px 5px !important;
    position: sticky;
    bottom: 0px;
    color: $topbarColor;
    background-color: $whiteColor !important;
}

.order-company-img {
    width: 70px;
    border-radius: 6px;
}

.history-div {
    box-shadow: $cardBodyBoxShadow;
    padding: 10px 20px;
    margin: 10px auto;
    border-radius: 4px;
    h6 {
        color: $darkOrangeColor;
        font-size: $fz13;
    }
    .sb-history-details {
        margin: 10px auto;
        padding: 10px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        img {
            width: 25%;
        }
    }
}