@import "variables";
@import "mixins";

.remove-manger{
    font-size: $fz13 !important;
    padding: 5px;
    color: $greenBlueColor;
    &:hover{
        color: $greenBlueColor;
        box-shadow: none;
    }
    i{
        padding-right: 3px;
    }
}
.viewsubbordinate-table{
    @include tableProperty;
}

