@import "variables";
@import "mixins";

.page-content-center {
    display: flex !important;
    align-items: center !important;
    .admin-login-img {
        // box-shadow: 0 0 6px -1.9px #7ce5aa;
        border-right: 1px solid #7ce5aa;
    }

    .admin-otp-form {
        .page-heading h6 {
            font-size: 28px !important;
        }
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 20px 50px;
        text-align: center;
        .otp-text {
            padding: 1px 10px 20px;
        }
        .admin-form{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 10px;
            .form-field-div {
                display: flex;
                align-items: center;
                justify-content: center;
                input{
                    width: 40px;
                    margin-right: 8px;
                }
            }
            p {
                padding-top: 15px;
                color: $greenBlueColor;
            }
        }
    }
}
.super-admin-page {
    padding: 20px;

    .page-heading {
        margin: 5px auto;
        padding: 10px;

        h6 {
            font-size: $fz20;
            font-weight: $fwt600;
            color: $darkOrangeColor;
        }
    }

    .super-admin-controls {
        // background-color: $lightGreen;
        box-shadow: $table_BoxShadow;
        margin: 10px auto;
        padding: 15px;
        border-radius: 10px;

        .accounts-header{
            margin: 0px 20px;
            font-size: 16px;
            font-weight: 700;
            color: $greenBlueColor;
        }

        .superadmin-links {
            display: flex;
            flex-wrap: wrap;
            // justify-content: left;
            justify-content: space-between;
            padding: 15px 10px;

            &.hyperlinks {
                border-bottom: 2px dotted $lightGray;
            }

            .single-card {
                margin: 12px;
                border: none;
                min-width: 45%;
                max-width: 45%;
                min-height: 60px;
                max-height: 60px;
                border-radius: 5px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                box-shadow: 0px 0px 6px -2.9px #7ce5aa;
                position: relative;

                &:hover {
                    box-shadow: $table_BoxShadow;
                    cursor: pointer;
                }

                .card-number {
                    background-color: #e9fcf5;
                    // padding: 10px;
                    width: 55px;
                    height: 45px;
                    margin-left: 10px;
                    text-align: center;
                    line-height: 40px;
                    // border-radius: 5px 0px 0px 5px; 
                    font-size: 25px;
                    // border: 1px solid;
                    border-radius: 50%;
                    color: #04a96d;

                    p {
                        font-size: $fz20;
                        color: $whiteColor;
                        font-weight: $fwt600;

                    }
                }

                .card-heading {
                    width: 100%;
                    text-align: left;
                    padding: 10px;

                    p {
                        font-size: $fz14;
                        font-weight: $fwt500;
                    }
                }

                .card-arrow {
                    right: 15px;
                    width: 25px;
                    height: 26px;
                    border: 1px solid $darkOrangeColor;
                    border-radius: 6px;
                    position: absolute;
                    font-size: 15px;
                    padding-left: 4px;
                    color: $darkOrangeColor;
                }
            }
        }
    }
}