@import './mixins';
@import './variables';

.products-view-main-cont{
    display: flex;
    width: 100%;
    background-color: $whiteColor;
    padding: 16px;
}
.products-view-first-div{
    width: 35%;
    
}
.products-view-first-div img{
    width: 300px;
    padding: 20px;
    border: 1px solid $palelightGreen;
    display: block;
}

.products-view-second-div{
    width: 65%;
}
.product-name h1{
    color: $topbarColor;
    font-size: 20px;
    font-weight: 600;
    display: contents;
}
.product-name h1 span{
    padding: 0;
    line-height: 1.4;
    font-size: inherit;
    font-weight: inherit;
    display: inline-block;
}
.product-price{
    display: flex;
    padding: 10px 0px;
}
.product-discount-price{
    color: $greenBlueColor;
    font-size: 20px;
    vertical-align: sub;
}
.product-mrp-price{
    font-size: 16px;
    margin-left: 12px;
    vertical-align: middle;
    color: $topbarColor;
    text-decoration: line-through;
}
.description-cont{
    /* display: flex; */
    box-shadow: $table_BoxShadow;
    line-height: 2;
    padding: 0px 10px;
}
.description{
    color: $topbarColor;
    font-size: 16px;
    font-weight: 600;
}
.description-about-product{
    color: $topbarColor;
    margin-bottom: 10px;
    font-size: 14px;
}
.specifications{
    box-shadow: $table_BoxShadow;
    padding: 10px 10px;
    font-size: 18px;
    margin-bottom: 8px;
    text-align: justify;
    .sub-header{
        color: $scrollerBackgroundColor;
    }
}
.products-specifications{
    display: grid;
    grid-template-columns: 200px 20px 500px;
    line-height: 2;
}
.products-specifications span{
    font-size: 13px;
    font-weight: 600;
}
.products-specifications b{
    font-size: 13px;
    color: $scrollerBackgroundColor;
}
.product-view-multiple-image-cont img {
    width: 100px;
    height: 100px;
}
.product-view-multiple-image-cont{
    display: flex;
    margin-top: 10px;
}
.products-btn{
    display: flex;
    padding: 10px;
    gap: 10px;
    justify-content: flex-start;
    border: none;
}
.products-btn button{
    width: 120px !important;
}
