@import "variables";
@import "mixins";


.routes-table {
    @include tableProperty;
}

.routes-scrollbar {
    height: calc(100vh - 200px);
    margin: 10px;
}

.routes-thead {
    @include tableHeadProperty;
}

.routes-table-data {
    .pause-play-btn {
        font-size: $fz18;

        .fa-pause-circle {
            // padding: 6px 7px;
            // border-radius: 50%;
            color: $dangerColor;
            background-color: $lightRed;
            animation-name: glow;
            animation-duration: 2s;
            animation-iteration-count: infinite;
        }

        .fa-play-circle {
            // padding: 6px 7px;
            // border-radius: 50%;
            color: $greenBlueColor;
            background-color: $palelightGreen;
            animation-name: glow;
            animation-duration: 2s;
            animation-iteration-count: infinite;
        }

        @keyframes glow {
            0% {
                padding: 0px 0px;
                border-radius: 50%;
            }

            25% {
                padding: 1px 2px;
                border-radius: 50%;
            }

            50% {
                padding: 3px 4px;
                border-radius: 50%;
            }

            75% {
                padding: 5px 6px;
                border-radius: 50%;
            }

            100% {
                padding: 6px 7px;
                border-radius: 50%;
            }
        }
    }
}

.routes-plan-table-data {
    tbody {
        tr {
            cursor: pointer;

            &:hover {
                background-color: rgba(124, 229, 169, 0.0823529412) !important;
            }
        }

        td {
            .status {
                padding: 4px 8px;
                border-radius: 6px;
                &.Completed, .completed {
                    color: $greenBlueColor !important;
                    background-color: $paleGreen;
                    border: 1px solid $greenBlueColor;
                }

                &.Pending, .pending {
                    color: $danderColor !important;
                    background-color: $lightRed;
                    border: 1px solid $danderColor;
                }
               &.Planned, .planned{
                    color: $darkOrangeColor;
                    background-color: $paleYellow;
                    border: 1px solid $darkOrangeColor;
               }
            }
        }
    }
}

.google-maps-route-model {
    .route-map-table {
        padding: 10px 15px;
        border-bottom: 2px dotted $grayColor;

        .fa {
            .fa {
                font-size: $fz15;
            }
        }
    }
}

.google-maps-route-model{
    .modal-dialog{
        max-width: 95%;
        margin: 2rem auto;
    }
}

.btn-add-routes {
    @include buttonProperty($greenBlueColor, 30px, auto, 0, 14px, $whiteColor);
    display: flex;
    align-items: center;
    margin-right: 10px;
    color: $whiteColor;
    // flex: none;
    &:hover {
        color: $whiteColor;
        box-shadow: none;
        background-color: $greenBlueColor;
    }

    &:focus {
        box-shadow: none;
    }

    p {
        font-size: $fz14;
        color: $whiteColor;
    }
}


.routes-edit-link {
    color: $greenBlueColor;
    font-size: $fz13;
    padding: 0px 5px;
    text-align: left;
    &:hover {
        color: $greenBlueColor;
    }
}


/* ADD & EDIT CLIENT MODEL START */
.main_model {
    // background-color: $whiteColor;
    padding: 10px;
    z-index: 9999;
}

.add-basefare-input {
    border-radius: 5px;
    font-size: $fz13;
    background-color: rgba(250, 250, 250, 0.8549019608);
}

.add-edit-basefare-model {
    .modal-content {
        width: 80% !important;
        margin-left: 50px;
    }

    .add-edit-basefare {
        padding: 10px 20px;

        .btns-save-cancel {
            display: inline-flex;
            margin-bottom: 10px;
            align-items: center;
            text-align: center;
            justify-content: center;

            p {
                font-size: $fz15;
                font-weight: $fwt400;
                position: relative;
                bottom: 3px;
            }

            &:hover {
                color: $whiteColor;
            }
        }

        .btn-save-up {
            background-color: $greenBlueColor;
            color: $whiteColor;
            border-radius: 5px;
            padding: 5px 15px;
            height: 30px;

            p {
                font-size: $fz15;
                font-weight: $fwt400;
                position: relative;
                bottom: 3px;
            }

            &:hover {
                color: $whiteColor;
            }
        }

        .btn-cancel {
            background-color: $grayColor;
            color: $whiteColor;
            border-radius: 5px;
            padding: 5px 15px;
            margin-right: 10px;
            height: 30px;

            p {
                font-size: $fz15;
                font-weight: $fwt400;
                position: relative;
                bottom: 3px;
            }

            &:hover {
                color: $whiteColor;
            }
        }
    }
}

.add-edit-basefare-heading {
    @include modelHeader;
}

/* SCROLL BAR CSS with Styling START */
/* width */
.routes-scrollbar::-webkit-scrollbar {
    height: 5px;
    width: 5px;
}

/* Track */
.routes-scrollbar::-webkit-scrollbar-track {
    background: $scrollerBackgroundColor;
}

/* Handle */
.routes-scrollbar::-webkit-scrollbar-thumb {
    background: $scrollerThumbColor;
    border-radius: 0px;
}

/* Handle on hover */
.routes-scrollbar:-webkit-scrollbar-thumb:hover {
    background: rgb(102, 102, 102);
}

// /* SCROLL BAR CSS with Styling END 