@import "variables";
@import "mixins";

p,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
}

.row {
    margin-left: 0px;
    margin-right: 0px;
    padding: 0px;
}

body {
    min-height: 100%;
    padding: 0px;
    margin: 0px;
    background: $paleWhite $mainBody_backgroundImg left top repeat;
    position: relative;
    font-size: $fz13;
    color: $topbarColor;
    overflow-x: hidden;
}

.pro-sidebar>.pro-sidebar-inner>.pro-sidebar-layout {
    position: fixed !important;
    box-shadow: 2px 2px 2px #eee !important;
}

.pro-sidebar>.pro-sidebar-inner {
    background-color: transparent;
}

.page-wrapper {
    .main-body {
        @include mainBody;
        transition: all 0.35s ease;
        transform: translateX(0);

        &.toggle-shift-left {
            @include toggleShiftLeft;
            transition: all 0.35s ease;
            transform: translateX(0);
        }
    }
}

.page-content {
    @include pageContentProperty;
    min-height: 86vh;
}

.page-content-div {
    @include pageContentProperty;
    background-color: $whiteColor;
    box-shadow: 0px 0px 3px 2px $whiteColor
}

.page-title {
    @include pageTitle;
}

.page-top-bar-dropdowns {
    display: flex;
}

.seleting-menus {
    @include selectingMenus;
    z-index: 1;

    .page-top-bar-dropdowns {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        // .react-select-list__control {
            // min-width: 140px !important;
        // }

        &:focus {
            box-shadow: none;
            border: 1px solid $paleGray;
        }

        .search-input {
            display: flex;
            position: relative;

            .search-field {
                min-width: 150px;
                padding: 5px 20px 5px 10px;
            }

            .fa-search {
                position: absolute;
                top: 12px;
                right: 12px;
            }
        }
    }
}

.select {
    @include SelectDropdowns;
    &:focus{
        box-shadow: $selectBoxShadow !important;
    }
}
.table> :not(:first-child) {
    border-top: none;
}

thead th::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-bottom: 2px solid $greenBlueColor !important;
    z-index: -1;
    // height: 2px;
    /* Set the height of the sticky border-bottom */
    // background-color: $greenBlueColor;
    /* Add your preferred border color */
    /* Place the border below the content */
}

tbody {

    tr {
        border-bottom: 1px solid $tableRow_borderBottom;
        td {
            border-bottom: 1px solid $tableRow_borderBottom !important;
        }
    }

    th {
        padding: 5px !important;
    }

    td {
        background-color: $whiteColor !important;
        padding: 5px 8px !important;
        vertical-align: middle;
        opacity: 1;
        overflow-wrap: break-word;
    }
}

thead tr th:first-child {
    // padding-left: 15px !important;
    vertical-align: middle;
}


//// MULTIPLE SELECTOR CSS START ///////
.multiSelectContainer li {
    padding: 5px 10px !important;
    color: $blackColor !important;
}

.multiSelectContainer li:hover {
    background-color: $palelightGreen !important;
    color: $blackColor !important;
    // background: $darkOrangeColor !important;
}

.select-add-user-model {
    &.zone {
        .searchWrapper {
            display: flex;
            flex-wrap: nowrap;
        }
    }

    .searchWrapper {
        padding: 5px !important;
        border-radius: 5px !important;
        display: flex;
        flex-wrap: wrap;

        .searchBox {
            margin-top: 0px !important;
        }
    }

    .optionContainer {
        border: "1px solid blue";
        border-radius: 0px !important;
        height: 130px;
    }

    .chip {
        background: $greenBlueColor;
        line-height: 16px !important;
        padding: 2px 5px !important;
        border-radius: 2px !important;
        margin-bottom: 2px !important;
        margin-right: 5px !important;
    }
}


//// MULTIPLE SELECTOR CSS END ///////

.form-select {
    padding: 0.26rem 2.25rem 0.38rem 0.75rem;
    border: 1px solid $modelhfborderColor;

}

.css-1u9des2-indicatorSeparator {
    background-color: $modelhfborderColor !important;
}

.css-1xc3v61-indicatorContainer {
    color: $modelhfborderColor !important;
    &:hover{
        color: $modelhfborderColor !important;
    }
}

.css-tj5bde-Svg{
    color: $modelhfborderColor !important;
}

.css-1nmdiq5-menu{
    li{
        color: $blackColor !important;
        &:hover{
            background-color: $modelhfborderColor !important;
            color: $blackColor !important;
        }
        &:active{
            background-color: $greenBlueColor;
            color: $blackColor !important;
        }
    }
}

.react-select-list {
    &__menu {
        margin: 0.125rem auto;
    }

    // &__menu-list{

    // }

    &__option {
        // background-color: rgb(252, 171, 171) !important;    

        &:hover {
            background-color: $modelhfbackgroundColor !important;
        }
    }
}

.searchWrapper,
.multiSelectContainer ul {
    background-color: $whiteColor;
    border: 1px solid $modelhfborderColor !important;
    box-shadow: $pageDropdown_boxShadow;
}

// .multiSelectContainer li:hover {
//     background-color: $modelhfborderColor !important;
// }

.btn-clear-select {
    @include buttonProperty($nav_back_Graycolor, 30px, 35px, 0, 16px, $whiteColor);
    align-items: center;
    text-align: center;
    padding: 3px;

    &:hover {
        background-color: $grayColor;
        box-shadow: none;
    }

    .bi-x-circle-fill {
        position: relative;
        color: $whiteColor;
    }
}

.btn-clear-track {
    @include buttonProperty($blackColor, 30px, auto, 0, 14px, $whiteColor);
    display: flex;
    align-items: center;

    i {
        padding-right: 3px;
    }
}

.btn-search-track {
    @include buttonProperty($grayColor, 30px, auto, 0, 14px, $whiteColor);
    // margin-right: 5px;
    margin: 0px 5px;
    align-items: center;
    padding: 5px 10px;

    &:hover {
        color: $whiteColor;
        box-shadow: none;
        background-color: $blackColor;
    }

    &:focus {
        box-shadow: none;
    }

    h6 {
        font-size: $fz14;
        line-height: 0.9;
        padding-left: 5px;
    }
}

.btn-go-back {
    @include buttonProperty($blackColor, 30px, auto, 0, 15px, $whiteColor);
    align-items: center;
    margin-left: 10px;
    margin-right: 10px;

    &:focus {
        box-shadow: none;
    }

    &:hover {
        color: $whiteColor;
    }

    h6 {
        font-size: $fz15;
        line-height: 0.9;
        padding-left: 6px;
    }
}

.download-and-pagination {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 50px;
    padding: 6px;
    position: relative;
    bottom: 0;
    right: 0;

    .data-count-per-page {
        margin-right: 10px;
    }
}

.btn-download-excel {
    @include buttonProperty($greenBlueColor, auto, auto, 0, 14px, $whiteColor);

    // &:hover {
    //     box-shadow: none;
    //     background-color: $darkOrangeColor;
    //     color: $whiteColor;
    // }

    &:focus {
        box-shadow: none;
    }
}

.modal-content {
    border-radius: 6px !important;
    border: none !important;
}

.fa-times {
    cursor: pointer;
    color: $grayColor;

    &:hover {
        color: $blackColor;
    }
}

.btns-save-cancel {
    @include modelFooter;
}

.btn-save-up {
    @include buttonProperty($greenBlueColor, auto, auto, 0, 15px, $whiteColor !important);
    // padding: 5px 15px;
    margin-right: 10px;

    p {
        font-size: $fz14;
        font-weight: $fwt400;
        position: relative;
        bottom: 3px;
    }

    &:hover {
        color: $whiteColor;
    }
}

.btn-cancel {
    @include buttonProperty($lightGreenTheme, auto, auto, 0, 14px, $whiteColor);
    // padding: 5px 15px;
    margin-right: 10px;

    p {
        font-size: $fz14;
        font-weight: $fwt400;
        position: relative;
        bottom: 3px;
    }

    &:hover {
        background-color: $whiteColor;
        color: $blackColor;
        // border: none;
        box-shadow: 0px 0px 8px -1.9px rgba(0, 0, 0, 0.2);
    }
}

.search_with-icon{
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    margin: auto 5px;
    .dropdown_search{
        height: 32px;
    }
    .fa{
        position: absolute;
        right: 10px;
    }
}

.no-data-found {
    text-align: center;
    align-items: center;
    color: $nav_back_Graycolor;
    font-size: $fz14;
    margin: 10px auto;
}

#inputFieldErrorMsg {
    color: $dangerColor !important;
    margin-top: 0px !important;
    position: absolute !important;
    font-size: $fz10 !important;
    left: 12px !important;
    top: 35px !important;
}

#inputFieldErrorMsgSuccess {
    color: $greenBlueColor !important;
    margin-top: 0px !important;
    position: absolute !important;
    font-size: $fz11 !important;
    left: 12px !important;
    top: 38px !important;
}

#kilometerMsgSuccess,
#twolevelclaimMsgSuccess {
    color: $greenBlueColor !important;
    margin-top: 0px !important;
    position: absolute !important;
    font-size: $fz11 !important;
    left: 12px !important;
    top: 38px !important;
}

.fa-angle-double-right,
.fa-angle-double-left {
    font-size: $fz10;
    font-weight: $fwt600;
    font-style: normal !important;
}

.error-input-message {
    font-size: $fz10;
    color: $dangerColor;
    position: relative;
    left: 0px;
}

// Hide Input Number Arrows START
/* Chrome, Safari, Edge, Opera */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

// Hide Input Number Arrows END

// Select Dropdown CSS START
.react-select-container-list.per-page {
    width: auto !important;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input,
.css-1uvydh2 {
    padding: 6.5px 14px !important;
    font-size: $fz14 !important;
}

.css-1j9v0by-MuiClock-root,
.MuiPickersPopper-root,
.css-1mtsuo7 {
    z-index: 9999 !important;
}

.react-select-container-list {
    min-width: 140px;
    margin: auto 5px;

    .react-select-list__control {
        position: relative;
        box-shadow: $pageDropdown_boxShadow;
        border: none;
        min-height: 30px !important;
        height: 30px !important;
        font-size: $fz13;
        border-radius: 5px;

        &:hover {
            border: none;
            box-shadow: $pageDropdown_boxShadow;
        }
        &:focus, &:focus-within {
            box-shadow: $selectBoxShadow !important;
        }

        .react-select-list__value-container {
            padding: 2px 0px 3px 5px;
            margin: 0px !important;
        }

        .react-select-list__indicator {
            padding: 2px 5px !important;
            // position: absolute;
            // right: 0px;
            // top: 2px;
        }

        .react-select-list__clear-indicator {
            padding: 2px 5px !important;
            position: absolute;
            right: 20px;
            top: 2px;
        }

        .react-select-list_menu {
            background-color: $paleGray !important;
            max-height: 200px !important;

            &:hover {
                background-color: $OrangeColor !important;
            }
        }

        .react-select-list__input-container {
            margin: 0px;
        }

        .react-select-list__indicator-separator {
            display: none;
        }
    }
}

// Select Dropdown CSS END

#tooltip-googlemap {
    z-index: 9999;
}

.form-text{
    padding: 2px 4px !important;
    font-size: 10px !important;
}

#Errorfails {
    color: $dangerColor !important;
}

/* DatePicker CSS END  */
.select.from-date {
    border-radius: 0px !important;
}

.select.to-date {
    margin-right: 8px !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

.from-to-date-select {
    align-items: center;
    flex-wrap: nowrap;
    .react-datepicker-popper{
        z-index: 9999 !important;
    }
    .select {
        width: 100% !important;
        max-width: 100% !important;
    }
    .input-group-text {
        // padding: 2px 5px;
        height: 30px;
        // margin: 5px 0px;
        font-size: $fz13;
        background-color: darkgrey;
        color: black;
    }
}

.page-header-text-div {
    padding: 2px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    &.order-details-page-header{
        justify-content: center !important;
    }
}
.page-header-text {
    color: $greenBlueColor;
    font-size: $fz13;
    font-weight: 700;
}

.date-picker-with-icon {
    display: flex;
    // width: 100px !important;
    position: relative;

    &.model-box {
        width: auto !important;

        .fa.calander-icon {
            position: absolute;
            top: 10px;
            right: 15px;
            font-weight: 500;
        }
    }
}
.react-datepicker-wrapper {
    width: 100%;
}
.fa.calander-icon {
    position: absolute;
    top: 10px;
    right: 5px;
    font-weight: 500;
}

.react-datepicker-popper[data-placement^=bottom] {
    z-index: 2;
}
// .react-select-list__control--menu--is--open {
//     z-index: 9999; /* Adjust the value as needed to ensure the dropdown appears above other elements */
//     position: fixed;
// }
// .react-select-list__menu {
.react-select-5-listbox {
    z-index: 9999 !important; /* Adjust the value as needed to ensure the dropdown appears above other elements */
    // position: fixed;
  }
  .react-datepicker-popper {
    z-index: 9999 !important;
}


.select.single-date,
.select.from-date,
.select.to-date {
    width: 100% !important;
    padding: 2px 5px 2px 5px !important;
}

.select.single-date.react-datepicker-ignore-onclickoutside,
.select.from-date.react-datepicker-ignore-onclickoutside,
.select.to-date.react-datepicker-ignore-onclickoutside {
    box-shadow: none;
    outline: none;
}

.select.from-date,
.select.to-date {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected,
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
    background-color: $greenBlueColor !important;
}

.page-header-row {
    display: flex;
    align-items: center;
    width: 100%;
}

/* DatePicker CSS END  */

.react-time-picker {
    margin-left: 12px;
    background-color: white;

}

.react-time-picker__wrapper {
    border: 1px solid $paleGray;
    border-radius: 5px;
}

.react-time-picker__inputGroup {
    padding: 0 5px !important;
}

.react-time-picker__inputGroup__input {

    &:hover,
    &:focus {
        outline: none;
        box-shadow: none;
        border: none;
    }
}

.react-time-picker__clear-button.react-time-picker__button {
    display: none;
    padding: 0px;
}

.form-check-input:checked {
    background-color: $greenBlueColor !important;
    border-color: $greenBlueColor !important;

}

.form-check-input:focus {
    box-shadow: none !important;
    border-color: $greenBlueColor !important;
}

.bulk-temp-and-res-message {
    .upload-res-message {
        &.success {
            color: green;
        }

        &.false {
            color: red;
        }
    }
}

.apikey-search-input {
    position: relative;
    width: 100%;
    margin-right: 10px;

    .input-search-api-key {
        font-size: $fz14;
        width: 510px !important;
        height: 32px;
    }

    .fa-search {
        position: absolute;
        right: 30px;
        top: 10px;
        color: $grayColor;
    }
}

.pl-0 {
    padding-left: 0 !important;
}

.pr-0 {
    padding-right: 0 !important;
}

.pt-0 {
    padding-top: 0 !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.p-0 {
    padding: 0 !important;
}

.ml-0 {
    margin-left: 0 !important;
}

.mr-0 {
    margin-right: 0 !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.m-0 {
    margin: 0 !important;
}

.text-center{
    text-align: center;
}

.dflex-j-between{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.hv-center{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}


/* PAGINATION PART START */

.user-pagination {
    margin-left: 10px !important;
}

.user-pagination ul {
    margin-bottom: 0px !important;
}

.page-link {
    color: $blackColor;
}

.page-link:focus {
    box-shadow: none;
}

.pagination {
    margin: auto;
    display: flex;
    list-style: none;
    outline: none;
}

.pagination>.active>a {
    background-color: $greenBlueColor;
    border-color: $greenBlueColor;
    color: $whiteColor;
}

.pagination>li>a {
    border: 1px solid $greenBlueColor;
    padding: 8px 15px;
    text-decoration: none;
    outline: none;
    cursor: pointer;
    margin-right: 5px;
}

.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus {
    background-color: $greenBlueColor;
    border-color: $greenBlueColor;
    outline: none;
    color: $whiteColor;
}

.pagination>li>a,
.pagination>li>span {
    color: $greenBlueColor;
}

.pagination>li:first-child>a,
.pagination>li:first-child>span,
.pagination>li:last-child>a,
.pagination>li:last-child>span {
    border-radius: unset;
}

.pagination>li:first-child>a {
    border-radius: 5px 0px 0px 5px;
}

.pagination>li:last-child>a {
    border-radius: 0px 5px 5px 0px;
}

.pagination>.disabled a {
    color: rgb(198, 197, 202);
    border: 1px solid rgb(198, 197, 202);
}

.page-range {
    display: inline-flex;
    margin-left: 20px;
}

// .css-t3ipsp-control {
//     border: none !important;
//     box-shadow: none !important;

//     &:hover,
//     &:visited {
//         border: none !important;
//     }
// }

/* PAGINATION PART END */


// CAPTCHA STYLE START

    .rc-anchor-light.rc-anchor-normal,
        .rc-anchor-light.rc-anchor-compact{
            border: 2px solid $greenBlueColor !important;
        }

    
    .rc-anchor-light {
        color: $statusGreen !important;
        border: 2px solid $greenBlueColor !important;
    }

    .rc-anchor-normal {
            height: 70px !important;
            width: 290px !important;
        }
    
.rc-anchor-logo-portrait {
    margin: 6px 0 0 5px !important;
    width: 58px !important;
    --webkit-user-select: none;
}
// CAPTCHA STYLE END

@media screen and (max-width: 476px) {
    .page-wrapper .main-body.toggle-shift-left {
        width: calc(100% - 70px);
        position: relative;
        right: 10px;
        transition: all 0.35s ease;
        transform: translateX(0);
    }

    .topbar-nav .dropdown-toggle {
        position: relative;
        right: 15px;
    }

    .card-dashboard {
        margin-bottom: 10px;

        .card-body {
            display: block;
            width: 100%;
            text-align: center;
        }
    }

    .card-left-img,
    .card-right-data {
        padding-left: 0px !important;
    }

    .card-imag {
        padding-right: 0px !important;
        border-right: none !important;
    }

    .dash-board-left-side,
    .user-activity-right-dash {
        margin-bottom: 10px !important;
    }

    .profile-right-part {
        width: 100% !important;
    }

    .seleting-menus {
        display: flex;

        .select {
            margin: 5px;
            box-shadow: none;
            border: 1px solid $paleGray;
            height: 30px;
            font-size: $fz13;
            max-width: 100% !important;
            border-radius: 0px;
        }

        .btn-clear-select {
            width: 100% !important;
            margin-left: 5px !important;
        }

        .btn-add-user {
            width: 100% !important;
            margin-left: 5px !important;
            align-items: center !important;
            text-align: center !important;
        }
    }

}

@media screen and (max-width: 1280px) {
    body {
        font-size: $fz13;
    }

    tbody td {
        font-size: $fz12 !important;
    }


    .row.view-on-map .col-md-9 {
        padding: 0px !important;
    }
}

.fz6 {
    font-size: $fz6;
}

.fz8 {
    font-size: $fz8;
}

.fz10 {
    font-size: $fz10;
}

.fz11 {
    font-size: $fz11;
}

.fz12 {
    font-size: $fz12;
}

.fz13 {
    font-size: $fz13;
}

.fz14 {
    font-size: $fz14;
}

.fz15 {
    font-size: $fz15;
}

.fz16 {
    font-size: $fz16;
}

.fz18 {
    font-size: $fz18;
}

.fz20 {
    font-size: $fz20;
}

.fz22 {
    font-size: $fz22;
}

.fz24 {
    font-size: $fz24;
}

.fz25 {
    font-size: $fz25;
}

.fz26 {
    font-size: $fz26;
}

.fz28 {
    font-size: $fz28;
}

.fz30 {
    font-size: $fz30;
}

.fz32 {
    font-size: $fz32;
}

.fz34 {
    font-size: $fz34;
}

.fz35 {
    font-size: $fz35;
}

.fz36 {
    font-size: $fz36;
}

.tb-td-sh {
    font-weight: 600;
    // color: $darkgrayColor;
}

.form-label {
    margin-bottom: 0px !important;
    color: $formLabelColor;
    font-size: $fz13;
}

.color-yellow {
    color: $darkOrangeColor;
}

.color-green {
    color: $greenBlueColor;
}

.gryclr {
    color: $darkgrayColor;
}

/* width */
.css-b62m3t-container
::-webkit-scrollbar {
    height: 5px;
    width: 4px;
    background-color: $paleWhite;
}
.css-41abqd-MuiTableContainer-root
::-webkit-scrollbar {
    height: 5px;
    width: 4px;
    background-color: $paleWhite;
}
/* width */
.css-b62m3t-container
::-webkit-scrollbar {
    height: 5px;
    width: 4px;
    background-color: $paleWhite;
}

/* Track */
.css-b62m3t-container
::-webkit-scrollbar-track {
    background: $scrollerBackgroundColor !important;
}

/* Handle */
.css-b62m3t-container
::-webkit-scrollbar-thumb {
    background: $darkOrangeColor !important;
    border-radius: 0px;
}



/* width */
.multiSelectContainer
::-webkit-scrollbar {
    height: 6px;
    width: 4px;
    background-color: $paleWhite;    
}

/* Track */
.multiSelectContainer
::-webkit-scrollbar-track {
    background: $scrollerBackgroundColor !important;
}

/* Handle */
.multiSelectContainer
::-webkit-scrollbar-thumb {
    background: $darkOrangeColor !important;
    border-radius: 0px;
}

/* Track */
.css-b62m3t-container
::-webkit-scrollbar-track {
    background: $scrollerBackgroundColor !important;
}

/* Handle */
.css-b62m3t-container
::-webkit-scrollbar-thumb {
    background: $darkOrangeColor !important;
    border-radius: 0px;
}
// ::-webkit-scrollbar {
//     height: 5px;
//     width: 4px;
// }

/* width */
.multiSelectContainer
::-webkit-scrollbar {
    height: 6px;
    width: 4px;
    background-color: $paleWhite;    
}

/* Track */
.multiSelectContainer
::-webkit-scrollbar-track {
    background: $scrollerBackgroundColor !important;
}

/* Handle */
.multiSelectContainer
::-webkit-scrollbar-thumb {
    background: $darkOrangeColor !important;
    border-radius: 0px;
}


/* SCROLL BAR CSS with Styling START */
/* width */
::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    background-color: $paleWhite !important;
    // &:hover{
    //     height: 8px !important;
    //     width: 8px !important;
    // }
}

/* Track */
::-webkit-scrollbar-track {
    background: $scrollerBackgroundColor !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $darkOrangeColor !important;
    border-radius: 0px;

}

// ::-webkit-scrollbar-thumb:hover {
//     height: 10px !important;
//         width: 10px !important;
//   }


/* Handle on hover */
:-webkit-scrollbar-thumb:hover {
    background: rgb(102, 102, 102);
}

/* SCROLL BAR CSS with Styling END */

// css for table
.custom-table-header {

    tr th {
        color: $tableHead_text_Color !important;
        padding: 8px !important;
        font-weight: 600;
        z-index: 1;
    }   
}

 
 .thead-cell::after {
     content: '';
     position: absolute !important;
     bottom: 0;
     left: 0;
     width: 100%;
     border-bottom: 2px solid $greenBlueColor !important;
     z-index: -1;     
     // height: 2px !important;   
 }
 

.custom-table-body tr td {
    font-size: 14px !important;
    padding: 8px !important;
    z-index: 0;
}

.action-class {
    text-align: center !important;
}

.css-1nmdiq5-menu {
    position: relative;
    z-index: 9999 !important;
    width: 200px !important;
}

.css-1nmdiq5-menu::-webkit-scrollbar {
    height: 5px !important;
    width: 5px !important;
}

/* Track */
.css-1nmdiq5-menu::-webkit-scrollbar-track {
    background: $scrollerBackgroundColor !important;
}

/* Handle */
.css-1nmdiq5-menu::-webkit-scrollbar-thumb {
    background: $scrollerThumbColor !important;
    border-radius: 0px !important;
}

.table-main-div {
    box-shadow: none !important;
    border-radius: 0px;
}

.table-align {
    text-align: center;
}

.page-header-div {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .breadcome-link {
        cursor: pointer;
    }

    .breadcome-text {
        position: relative;

        // &::before {
        //     // float: left;
        //     padding: 0px 5px;
        //     content: "\f0142";
        // }
    }
}

.css-1ex1afd-MuiTableCell-root {
    font-family: "DM Sans",sans-serif !important;
    color: $topbarColor !important;
    font-size: 13px !important;
}

// pgae header filter section
.page-filter-section {
    // position: absolute;
    display: flex;
    align-items: center;
    padding: 5px;
    left: 0;
    width: 100%;
    z-index: 9999;
    background-color: $whiteColor;
    box-shadow:  $filterShadow;
    // box-shadow: rgba(4, 169, 109, 0.318) 0px 3px 3px -2px;
    .clients-filter-input-boxs {
        padding: 10px;
        &.filter-box-div{
            display: flex;
            align-items: center;
            justify-content: space-between;
            .from-to-date-select{
                margin: auto 5px !important;
                .date-picker-with-icon{
                    width: 100% !important;
                }
            }
        }
    }
    .date-filter {
        min-width: 175px;
    }
}
.cliam-filter-section {
    // position: absolute;
    display: flex;
    align-items: center;
    left: 0;
    width: 100%;
    z-index: 9999;
    background-color: $whiteColor;
    // box-shadow: rgba(4, 169, 109, 0.318) 0px 3px 3px -2px;
    .clients-filter-input-boxs {
        padding: 5px;
        &.filter-box-div{
            display: flex;
            align-items: center;
            justify-content: space-between;
            .from-to-date-select{
                margin: auto 5px !important;
                .date-picker-with-icon{
                    width: 100% !important;
                }
            }
        }
    }
    .date-filter {
        min-width: 175px;
    }
}

.css-1yhpg23-MuiTableCell-root {
    color: $topbarColor !important;
}

.add-edit-basefare-head {
    font-weight: 700;
    color: $greenBlueColor;
}
.custom-auto-select {
    .dropdown-menu.show{
        font-size: 13px !important;
        padding: 0px;
        z-index: 9999;
        .dropdown-item{
            border-bottom: 1px solid #eee;
            color: $topbarColor;
            &:hover{
                background-color: #e0f3e8;
                color: $greenBlueColor;
            }
        }
    }
}
.basefare-table {
    th:first-child {
        position: sticky;
        left: 0;
        z-index: 2;
    }
    td:first-child {
        position: sticky;
        left: 0;
    }
}

// Buy now button
.btn-buynow-btn {
    @include buttonProperty($greenBlueColor,
        30px,
        auto,
        0,
        14,
        $whiteColor);
        margin: 0px 10px;
        border-radius: 6px;

    &:hover {
        color: $whiteColor;
        box-shadow: none;
        background-color: $greenBlueColor;
    }

    &:focus {
        box-shadow: none;
    }

    h6 {
        font-size: $fz14;
        padding-left: 5px;
    }
}
// Buy now button
// Create List button
.btn-createList-btn {
    @include buttonProperty($darkOrangeColor,
        30px,
        auto,
        0,
        14,
        $whiteColor);
        margin: 0px 10px;
        position: relative;

        .list-count {
            position: absolute;
            right: -8px;
            top: -8px;        
            width: 20px;
            height: 20px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $greenBlueColor;
            font-size: 11px;
            color: $whiteColor;
        }

    &:hover {
        color: $whiteColor;
        box-shadow: none;
        background-color: $darkOrangeColor;
    }

    &:focus {
        box-shadow: none;
    }

    h6 {
        font-size: $fz14;
        padding-left: 5px;
    }
}

// Create List button

// Create List button
.btn-checkoutList-btn {
    @include buttonProperty($greenBlueColor,
        30px,
        auto,
        0,
        14,
        $whiteColor);
        margin: 0px 10px;
        position: relative;
        border-radius: 6px;

    &:hover {
        color: $whiteColor;
        box-shadow: none;
        background-color: $darkOrangeColor;
    }

    &:focus {
        box-shadow: none;
    }

    h6 {
        font-size: $fz14;
        padding-left: 5px;
    }
}

// Create List button

// pgae header filter section
// new design css

// Add Buttton With Plus Icon
.add-single-bulk {
    .btn-add-dropdown {
        // @include addButtonProperty($greenBlueColor,
        //     auto, 10%, 18px,
        //     $whiteColor,4px, 8px);
            @include addButtonProperty($greenBlueColor,
            auto, 4px, 18px,
            $whiteColor,0px, 8px);
    
        &:hover {
            color: $whiteColor;
        }
    
        &:focus {
            box-shadow: none;
        }
    }
    .btn-add-save-update {
        @include addButtonProperty($greenBlueColor,
            80px, 3px, 14px,
            $whiteColor, 3px, 10px
            );
        font-weight: 700;
    
        &:hover {
            color: $whiteColor;
        }
    
        &:focus {
            box-shadow: none;
        }
    }
    .btn-sb-cancel {
        @include addButtonProperty($darkOrangeColor,
            80px, 3px, 14px,
            $whiteColor, 3px, 10px
            );
        font-weight: 700;
    
        &:hover {
            color: $whiteColor;
        }
    
        &:focus {
            box-shadow: none;
        }
    }
    .btn-sb-back {
        @include addButtonProperty($darkOrangeColor,
            80px, 3px, 14px,
            $whiteColor, 3px, 10px
            );
        font-weight: 700;
    
        &:hover {
            color: $whiteColor;
        }
    
        &:focus {
            box-shadow: none;
        }
    }
    .single-bulk-upload {
        transform: translate(0px, 32px) !important;
        margin-left: 0px !important;
        margin-top: 0px !important;
        border-radius: 5px !important;
        background-color: $blackColor;
        min-width: max-content !important;
        font-size: $fz13 !important;

        li {
            button {
                color: $whiteColor;

                &:hover {
                    color: $darkOrangeColor;
                }
            }
        }

        .dropdown-item:focus,
        .dropdown-item:active,
        .dropdown-item:hover {
            background-color: transparent !important;
        }
    }
}

.table-input-div {
    display: flex;
    align-items: center;
    justify-content: center;
}
// Add Buttton With Plus Icon


// Css for floating lables
.form-floating>.form-control, .form-floating>.form-control:not(:placeholder-shown), .form-floating:focus-within>.form-control {
    padding: 6px 15px;
    line-height: 25px;
    height: 35px;
    &.form-textarea{
        height: auto !important;
    }
}
 .float-input-visible>label, .float-input-visible:focus-within>label, .float-input:focus-within>label {
    display: inline-block !important;
    position: absolute;
    top: -3px;
    left: 5px;
    color: $greenBlueColor;
    background-color: $whiteColor;
    padding: 0px 3px;
    font-size: 14px;
    // font-weight: 600;
    height: 22px;
    border-radius: 4px;
    opacity: .8 !important;
    transition: opacity .1s ease-in-out,transform .1s ease-in-out;
}
 .float-visible-select>label, .float-visible-select:focus-within>label, .float-select:focus-within>label {
    display: inline-block !important;
    position: absolute;
    top: -10px;
    left: 5px;
    color: $greenBlueColor;
    background-color: $whiteColor;
    padding: 0px 3px;
    font-size: 12px;
    height: 18px;
    border-radius: 4px;
    opacity: .8 !important;
    transition: opacity .1s ease-in-out,transform .1s ease-in-out !important;
}
 .float-input>label {
        display: inline-block !important;
        position: absolute;
    top: auto;
    left: 0;
    bottom: 10px;
    height: 100%;
    padding: 1rem 0.75rem;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    transition: opacity .1s ease-in-out, transform .1s ease-in-out;
    
}
.float-select>label{
    display: none !important;
    // .react-select-list__placeholder .css-1jqq78o-placeholder{
    //     /* Your styling properties */
    //     color: red !important;
    //     font-style: italic;
    //   }
}
.custom-auto-select{
    input:focus::placeholder{
        color: transparent !important;
      }
}
.float-visible-select, .float-select:focus-within {
    .css-1jqq78o-placeholder{
        display: none;
      }
}

.upload-logo {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    font-size: 13px;
    background-image: radial-gradient($whiteColor, $lightGray 70%);
    // background-color: $lightGray;
    color: $blackColor;
    text-align: center;
    padding: 5px;
    img{
        width: 18%;
    }
    &:hover {
        color: $whiteColor;
        background-image: radial-gradient($whiteColor, $paleOrange 70%);
    }
}
.delete-upload-logo {
    position: absolute;
    top: 0px;
    right: 5px;
    font-size: 16px;
    color: $darkOrangeColor;
    cursor: pointer;
}

// Css for floating lables

// common css for status badge
.status-badge {
    padding: 3px 15px;
    color: $whiteColor;
    border-radius: 5px;
    font-weight: 600;
    font-size: 13px;
}
.css-41abqd-MuiTableContainer-root, .MuiTableContainer-root {
    min-height: 70vh !important;
    max-height: 70vh !important;
}

.pending-status, .Pending {
    background-color: $pendStatusBackground;
    color: $pendStatusColor;
    border: 1px solid $pendStatusBackground;
}
.follow-status, .Follow {
    background-color: $followStatusBackground;
    color: $followStatusColor;
    border: 1px solid $followStatusBackground;
}
.complete-status, .Cancelled, .Completed {
    background-color: $compStatusBackground;
    color: $compStatusColor;
    border: 1px solid $compStatusBackground;
}
.cancel-status, .PCancelled,
.ReSchedule {
    background-color: $cancStatusBackground;
    color: $cancStatusColor;
    border: 1px solid $cancStatusBackground;
}
.expire-status, .Expired {
    background-color: $expirStatusBackground;
    color: $expirStatusColor;
    border: 1px solid $expirStatusBackground;
}

.back-btn {
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    margin: 0px 15px 0px 5px;
    background-color: $pendStatusBackground;
    border-radius: 50%;
    cursor: pointer;
    &:hover{
        box-shadow: 0px 0px 8px -1.9px $pendStatusColor;
    }
    img {
        width: 72%;
    }
}
// common css for status badge

// css for swal alert
div:where(.swal2-icon) {
    width: 65px !important;
    height: 65px !important;
}
div:where(.swal2-icon).swal2-success [class^=swal2-success-line][class$=tip] {
    top: 2.3em !important;
    left: 0.925em !important;
    width: 1em !important;
}
div:where(.swal2-icon).swal2-success [class^=swal2-success-line][class$=long]{
    top: 2em !important;
    right: 0.8em !important;
    width: 1.937em !important;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm{
    background-color: $greenBlueColor !important;
    &:focus{
        box-shadow: none !important;
    }
    font-size: 13px;
}
// css for swal alert

// Tooltip css
#tooltip-dashboard {
    .tooltip-inner{
        background-color: $toolTipBackground !important;
        color: $pendStatusColor !important;
    }
}
.tooltip-arrow::before {
    border-bottom-color: transparent !important;
    border-top-color: transparent !important;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
}
// Tooltip css


// Sticky table header
.three-header-sticky th:first-child, .three-header-sticky td:first-child{
    position: sticky;
    left: 0;
    background-color: white; /* To hide the sticky columns' background color */
  }

.three-header-sticky th:nth-child(2), .three-header-sticky td:nth-child(2)
{
    position: sticky;
    left: 100px;
    background-color: white; /* To hide the sticky columns' background color */
  }

.three-header-sticky th:first-child, .three-header-sticky th:nth-child(2), 
.three-header-sticky th:nth-child(3) {
    z-index: 2;
}
.three-header-sticky td:first-child, .three-header-sticky td:nth-child(2), 
.three-header-sticky td:nth-child(3) {
    z-index: 1;
}

.three-header-sticky th:nth-child(3), .three-header-sticky td:nth-child(3) {
    position: sticky;
    left: 200px;
    background-color: $whiteColor; /* To hide the sticky columns' background color */
  }


#loading-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 999;
    // background-color: rgba(0, 0, 0, 0.3333333333);
    background-color: rgb(0 0 0 / 6%);
    display: none;
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  #loading-wrapper.show {
    display: block;
  }
  #loading-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    position: absolute;
    top: calc(50% - 38px);
    left: 50%;
    color: $whiteColor;
    width: 115px;
    height: 115px;
    margin: -19px 0 0 -58px;
    text-align: center;
    font-size: 20px;
    z-index: 9999;
    background-color: $lightGreen;
    border-radius: 50%;
  }
  #loading-text img {
    width: 100%;
  }
  #loading-content {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 170px;
    height: 170px;
    margin: -85px 0 0 -85px;
    border: 3px solid $dangerColor;
  }
  #loading-content:after {
    content: "";
    position: absolute;
    border: 3px solid $successColor;
    left: 15px;
    right: 15px;
    top: 15px;
    bottom: 15px;
  }
  #loading-content:before {
    content: "";
    position: absolute;
    border: 3px solid $greenBlueColor;
    left: 5px;
    right: 5px;
    top: 5px;
    bottom: 5px;
  }
  #loading-content {
    border: 3px solid transparent;
    border-top-color: $darkOrangeColor;
    border-bottom-color: $darkOrangeColor;
    border-radius: 50%;
    -webkit-animation: loader 2s linear infinite;
    animation: loader 2s linear infinite;
  }
  #loading-content:before {
    border: 3px solid transparent;
    border-top-color: $whiteColor;
    border-bottom-color: $whiteColor;
    border-radius: 50%;
    -webkit-animation: loader 3s linear infinite;
    animation: loader 3s linear infinite;
  }
  #loading-content:after {
    border: 3px solid transparent;
    border-top-color: $greenBlueColor;
    border-bottom-color: $greenBlueColor;
    border-radius: 50%;
    -webkit-animation: loader 1.5s linear infinite;
    animation: loader 1.5s linear infinite;
    -moz-animation: loader 2s linear infinite;
    -o-animation: loader 2s linear infinite;
  }
//   @-webkit-keyframes loaders {
//     0% {
//       -webkit-transform: rotate(0deg);
//       -ms-transform: rotate(0deg);
//       transform: rotate(0deg);
//     }
//     100% {
//       -webkit-transform: rotate(360deg);
//       -ms-transform: rotate(360deg);
//       transform: rotate(360deg);
//     }
//   }
  @-webkit-keyframes loader {
    0% {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    0% {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  #content-wrapper {
    color: $whiteColor;
    position: fixed;
    left: 0;
    top: 20px;
    width: 100%;
    height: 100%;
  }

  .select-time-input {
    width: 100% !important;
    border: 1px solid transparent !important;
    .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root{
        border: 1px solid transparent !important;
    }
    
  }
// css for loader