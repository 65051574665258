@import "variables";
@import "mixins";


.account-settings-page{
    position: relative;
    height: calc(100vh - 90px);
    .account-settings-page-body{
    margin: 15px;
    position: relative;
    // height: calc(100vh - 200px);
    }
}

.claim-two-aprroval h6,
.meeting-range-head h6,
.client-mapping-setting-title h6 {
    font-size: $fz14;
    padding-bottom: 5px;
    border-bottom: 1.3px solid $grayColor;
    color: $OrangeColor;
    opacity: 1;
    width: 400px;
}


.allow-meeting-range-select-radio {
    margin-top: 10px;
}

.claim-two-level.approval-enable-radio {
    margin-top: 10px;
}

.approval-enable-radio-status {
    margin: auto 25px 0px 0px;
}

.btn-success.claim-approval-update,
.btn-success.meeting-range-update,
.btn-success.mappingtype-update {
    width: 400px;
    background-color: $greenBlueColor;
    border: none;
    border-radius: 0px;
}

