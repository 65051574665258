@import "variables";

.view-meet-details-model {
    .modal-content {
        width: 800px !important;
        position: relative !important;
        right: 100px !important;
    }
}

.meeter-reading-model{
    .modal-content {
            width: 800px !important;
            position: relative !important;
            right: 100px !important;
        }
}