@import './variables';



.search-row {
    display: flex;
    align-items: center;
    padding: 5px 5px;
    box-shadow: rgba(4, 169, 109, 0.318) 0px 3px 3px -2px
}

.category-search-row {
    .react-select-container-list {
        width: 100% !important;
        margin: auto !important;
    }

    .btn-search-track {
        margin: 0px !important;
        width: 100%;
        padding: 5px !important
    }
}

.cat-col {
    padding: 0px 5px !important;
}

.sb-table-setion {
    padding: 10px;
}

.sb-dashboard-table {
    padding: 0px 20px;

    .css-y8ay40-MuiTableCell-root {
        font-size: 15px;
        font-family: "DM Sans", sans-serif !important;
    }
}

.sb-table-div {
    .css-sgafyg-MuiPaper-root {
        margin: 0px;

        .css-y8ay40-MuiTableCell-root {
            padding: 8px;
            font-weight: 600;
            font-family: "DM Sans", sans-serif !important;
        }
    }
}

.facility-details {
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-table-body tr td {
    color: $topbarColor;
}

.form-select {
    font-size: $fz14;
    border: 1px solid $lightGray !important;
    padding: 6px 15px;
    color: $topbarColor;
    line-height: 20px;
    height: auto;
}

.form-select:focus {
    border: 1px solid $lightGray !important;
    outline: none;
    box-shadow: none;
}

// Category Modale
.category-modal-div {
    .modal-dialog {
        max-width: 35%;

        .modal-content {
            padding: 20px;
            border: none;
            box-shadow: rgba(4, 169, 109, 0.318) 0px 1px 4px 0px;



            .sb-btn-section {
                display: flex;
                justify-content: flex-end;
                align-items: center;
            }

            .modale-header-div {
                display: flex;
                align-items: center;
            }
        }
    }
}

.form-field {
    padding: 5px 0px;
}

.form-status-section {
    display: flex;

    .btn-status {
        padding: 0px 20px;
    }

    .disable-text {
        margin-left: 50px;
    }

    .enable-text {
        margin-left: 30px;
    }
}

.close-popup {
    border: none;
    background-color: transparent;
    font-size: 20px;
    font-weight: 800;
}

// Category Modale