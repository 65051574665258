@import "variables";
@import "mixins";


.product-category-table {
    @include tableProperty;
}

.product-category-scrollbar {
    height: calc(100vh - 200px);
    margin: 10px;
}

.product-category-thead {
    @include tableHeadProperty;
}


.btn-add-product-category {
    @include buttonProperty($greenBlueColor, 30px, auto, 0, 14px, $whiteColor);
    margin-right: 10px;

    // flex: none;
    &:hover {
        color: $whiteColor;
        box-shadow: none;
        background-color: $greenBlueColor;
    }

    &:focus {
        box-shadow: none;
    }

    h6 {
        font-size: $fz14;
        margin-left: 2px;
    }
}

.sub-category-table {
    position: relative;
    margin: auto;
}
    
.product-category-row-data {
    .edit-link {
        color: $greenBlueColor;
        cursor: pointer;
    }

    .fa-chevron-down, .fa-chevron-right{
        cursor: pointer;
    }

    .product-category-edit-link {
        color: $greenBlueColor;
        font-size: $fz13;
    }

    &.sub-row-accordian-body {
        background-color: $whiteColor !important;
        padding: 5px !important;
        color: $darkgrayColor;
        border-style: hidden;

        .fa-pencil {
            color: $greenBlueColor;
        }
    }
}




/* ADD & EDIT CLIENT MODEL START */
.main_model {
    // background-color: $whiteColor;
    padding: 10px;
    z-index: 9999;
}

/* SCROLL BAR CSS with Styling START */
/* width */
.product-category-scrollbar::-webkit-scrollbar {
    height: 5px;
    width: 5px;
}

/* Track */
.product-category-scrollbar::-webkit-scrollbar-track {
    background: $scrollerBackgroundColor;
}

/* Handle */
.product-category-scrollbar::-webkit-scrollbar-thumb {
    background: $scrollerThumbColor;
    border-radius: 0px;
}

/* Handle on hover */
.product-category-scrollbar:-webkit-scrollbar-thumb:hover {
    background: rgb(102, 102, 102);
}

/* SCROLL BAR CSS with Styling END */