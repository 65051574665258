@import './variables';
@import './mixins';

.custom-field-form {
    padding: 20px 5px;
}
.custom-right-col {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.custom-left-header{
    padding: 10px 10px;
    
        h6 {
            font-size: 14px;
        }
} 
.custom-right-header {
    padding: 10px 10px 0px;
    h6{
        font-size: 14px;
    }
}
.custom-right-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .sp-custom-field-select {
        min-width: 40%;
    }
}
.adding-field-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px 5px;

    .vline{
        width: 2px;
        background-color: aliceblue;
        height: 50px;
        margin: 5px 0px;
    }
}
.page-name-custom {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 10px;
    margin: 0px 5px;
}

.page-name-custom h6 {
    font-size: 14px;
}