@import "variables";
@import "mixins";

.closemenu {
    color: $topbarColor;
    position: relative;
    right: 0;
    left: 200px;
    z-index: 999;
    font-size: $fz18 !important;
    top: 0;
    transition: all 0.35s ease;
    transform: translateX(0);
    cursor: pointer;
}

.all-page-header-section{
    position: absolute;
    left: 20%;
    top: 18%;
}

.topbar-nav .toggle-shift-left {
    position: relative;
    left: 75px;
    z-index: 9;
}

.topbar-nav .dropdown-toggle {
    background-color: transparent;
    border: none;
    position: relative;
    top: 0;
    right: 0;
    color: $whiteColor;
    display: inline-flex;
    // width: 235px;
    box-shadow: none;
    margin-right: 30px;
}

.topbar-nav .dropdown-toggle:hover {
    background-color: transparent;
    border: none;
    box-shadow: none;
}

.custom-sb-drop {
    .dropdown-item:hover {
            background-color: $whiteColor;
        }
    position: absolute; inset: 0px 0px auto auto; margin: 0px; transform: translate(0px, 64px);
}

.dropdown-toggle::after {
    content: "";
    width: 30px;
    height: 30px;
    background: url("../../public/images/Topbar/down-caret.png");
    background-repeat: no-repeat !important;
        background-position: center !important;
        background-size: contain !important;
    font-size: $fz30;
    // background-color: red;
    position: absolute;
    top: 15px;
    right: -20px;
    border-top: 0 solid #1B1E24;
        border-right: 0 solid transparent;
        border-bottom: 0;
        border-left: 0 solid transparent;
}

.header-item {
    .dropdown-menu.show {
        display: block;
        transform: translate(-30px, 65px) !important;
        // margin-left: 35px !important;
        // margin-top: 25px !important;
        // box-shadow: 0px 0px 2px 2px rgb(0 0 0 / 20%) !important;
        // border-top-style: hidden;
    }
}

.logout-text {
    color: rgb(122, 2, 2);
    font-weight: 600;
}

.dropdown-item:hover {
    color: $whiteColor;
    // background-color: $greenBlueColor;
}
.dropdown-item:active {
    background-color: #e9ecef !important;
}

.icon-divider {
    border-right: 1px solid #74d9b4;
    height: 20px;
}

.dropdown-item:focus {
    color: $whiteColor;
    background-color: $greenBlueColor;
}


.topbar {
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    // height: 52px;
    height: 63px;
    background: #fff;
    z-index: 9;
    box-shadow: rgba(4, 169, 109, 0.318) 0px 3px 3px -2px;
    // box-shadow: 2px 5px 11px #f1f1f1;
}

.navbar-custom {
    position: relative;
    left: 0;
    top: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    height: 63px;
}

.logo-img {
    border-radius: 50%;
    // border: 3px solid #21ab79;
}

.list-unstyled {
    padding: 5px;
    height: 63px;
    display: flex;
    align-items: center;
}

.sb-user-name {
        text-align: left;
        font-weight: 700;
        color: $topbarColor;
        padding-top: 5px;
        font-size: 15px;
}

.sb-company-name {
    font-weight: 500;
}

.topbar-nav {
    .button-menu-mobile {
        background-color: transparent;
        box-shadow: none;
        border: none;
        outline: none;
        &:hover,
        &:focus {
            box-shadow: none;
            outline: none;
        }
    }
}

.logout-modal-div {
    top: 35%;
    .modal-content{
        padding: 25px;
        .sb-logout-btn, .log-out-div {
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .log-out-div{
            text-align: center;
            flex-direction: column;
            p {
                margin-top: 10px;
            }
        }
    }
}

// .top-bar-popup {
//     position: relative;
//     height: 100%;
//     overflow: hidden;
//     display: flex;
//     align-items: center;
//     width: 150px;
//     .top-bar-popup {
//         position: absolute;
//         top: 1px !important;
//         width: 100% !important;
//     }
// }

.top-bar-popup {
    position: relative;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    width: 150px;
    .dashboard-pop-img {
        margin-left: 15%;
        height: 100%;
      }
      .carousel-div {
        position: absolute;
        top: 0px !important;
        width: 100% !important;

        .first-popup-div {
            min-height: 50px;
            min-width: 100px;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0;
            box-shadow: 0px 0px 8px -1.9px #aec3bb;
            background: #f8fefe;
            padding: 5px 10px;
            .first-popup-div-text {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                p {
                    font-size: 12px;
                    text-align: center;
                }
            }
        }
        .first-popup-div.active {
            opacity: 0;
            animation: 4.5s anim-popoutin ease;
            &:hover {
                animation-play-state: paused;
              }
        }
          @keyframes anim-popoutin {
            0%{
              transform: scale(0);
              opacity: 0;
              text-shadow: 0 0 0 rgba(0, 0, 0, 0);
            }
            30% {
                transform: scale(1);
                opacity: 1;
                // text-shadow: 3px 10px 5px rgba(0, 0, 0, 0.5);
              }
            50% {
              transform: scale(1);
              opacity: 1;
            }
            70% {
                transform: scale(1);
                opacity: 1;
              }
             100% {
              transform: scale(0);
              opacity: 0;
            }
          }
          .carousel-text2 {
            background-color: #e2bc13;
            transition: opacity 3s ease-in-out;
            transition-delay: 3s;
          }
          .carousel-text3 {
            background-color: #5fdbc0;
            transition: opacity 3s ease-in-out;
            transition-delay: 3s;
          }
          
        //   .carousel-text.active {
        //     opacity: 1;
        //   }
      }
      
  }


.your-days-left {
    position: relative;
    top: 0px;
    right: 130px;
    color: $whiteColor !important;
    animation-name: moving;
    animation-duration: 8s;
    animation-iteration-count: 4;
    animation-direction: alternate;
    opacity: 1;

    .go-to-accounts {
        text-decoration: none;
        color: $whiteColor !important;
    }

    .days-left-in-word {
        font-size: $fz14;
        color: red;

        b {
            font-size: $fz15;
        }

        i {
            padding-right: 5px;
        }
    }

    .days-left-in-number {
        font-size: $fz14;
        color: $darkOrangeColor;

        b {
            font-size: $fz15;
        }

        i {
            padding-right: 5px;
        }

        &.red {
            color: red;
        }
    }
}

@keyframes moving {
    0% {
        top: 13px;
        right: 130px;
    }

    100% {
        top: 13px;
        right: 350px;
    }
}