@import "variables";
@import "mixins";


.user-table {
    @include tableProperty;
}

.users-scrollbar {
    height: calc(100vh - 200px);
    // height: 65vh;
    margin: 10px;
}

.color-yellow {
    color: #ffb900;
    font-size: 12px;
}

.user-mobile {
    color: #04a96d;
    font-size: 12px;
}

.users-scrollbar::-webkit-scrollbar-thumb {
    background: $darkOrangeColor;
    border-radius: 0;
}

.users-list-thead {
    @include tableHeadProperty;

}

.btn-add-user {
    @include buttonProperty($greenBlueColor,
        30px,
        auto,
        0,
        13px,
        #FFF);
    font-weight: 700;

    &:hover {
        color: $whiteColor;
    }

    &:focus {
        box-shadow: none;
    }

    h6 {
        font-size: $fz13;
        padding-left: 5px;
    }
}

.table-data-users-imgages {
    @include userImgProperty(30px);
    margin-right: 10px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 6px -0.9px #7ce5aa;
    object-fit: cover;
    &:hover{
        box-shadow: 0px 0px 6px -0.4px #7ce5aa;
    }
    &.default-image{
        color: #fff;
        font-weight: 700;
        font-size: 16px;
        height: 40px !important;
        width: 40px !important;
    }
    &.company-logo{
        color: #fff;
        font-weight: 700;
        font-size: 16px;
        height: 40px !important;
        width: 40px !important;
        object-fit: contain;
    }
}

.users-list-name {
    @include tableDataImgTxt;

    h6 {
        font-size: $tableData_headTextSize;
    }
}

.user-action-td {
    align-items: center;
    justify-content: space-around;

    .user-edit-link,
    .user-view-link {
        padding: 5px;
        text-decoration: none;
        color: $greenBlueColor;

        &:hover {
            color: $greenBlueColor;
        }
    }
}

.subbordinate-link {
    color: orangered;

    // display: inline-flex;
    .user-icon {
        color: $greenBlueColor;
        cursor: pointer;
        padding-left: 5px;
    }
}

.add-single-bulk-user {
    .single-bulk-upload {
        transform: translate(0px, 32px) !important;
        margin-left: 0px !important;
        margin-top: 0px !important;
        border-radius: 5px !important;
        background-color: $blackColor;
        min-width: max-content !important;
        font-size: $fz13 !important;

        li {
            button {
                color: $whiteColor;

                &:hover {
                    color: $darkOrangeColor;
                }
            }
        }

        .dropdown-item:focus,
        .dropdown-item:active,
        .dropdown-item:hover {
            background-color: transparent !important;
        }
    }
}

.template-download {
    background-color: $greenBlueColor;
    border-radius: 10px;
    font-size: $fz14;
    color: $whiteColor;
    float: right;

    &:hover {
        color: $whiteColor;
    }
}

@media screen and (max-width: 1280px) {
    .users-list-name {
        h6 {
            font-size: 0.8rem;
        }
    }
}

/* SCROLL BAR CSS with Styling START */
/* width */
.users-scrollbar::-webkit-scrollbar {
    height: 5px;
    width: 5px;
}

/* Track */
.users-scrollbar::-webkit-scrollbar-track {
    background: $scrollerBackgroundColor;
}

/* Handle */
.users-scrollbar::-webkit-scrollbar-thumb {
    background: $darkOrangeColor;
    border-radius: 0px;
}

/* Handle on hover */
.users-scrollbar:-webkit-scrollbar-thumb:hover {
    background: $darkOrangeColor;
}

/* SCROLL BAR CSS with Styling END */