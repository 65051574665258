/* @import url('https://fonts.googleapis.com/css2?family=Finlandica:ital,wght@0,400;0,500;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Hind:wght@300;400;500;600;700&display=swap'); 
@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Asap:wght@200&family=Barlow+Condensed:wght@300;400&family=Tilt+Neon&family=Titillium+Web:wght@300&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');


* {
  /* font-family: 'Roboto Condensed', sans-serif; */
  /* font-family: 'Inter', sans-serif; */
  /* font-family: 'Heebo', sans-serif;  */
  /* font-family: 'Hind', sans-serif; */
  /* font-family: 'Arimo',sans-serif; */
  /* font-family: 'Nunito Sans', sans-serif; */
  /* font-family: "DM Sans",sans-serif; */
  font-family: 'Inter', sans-serif;
}

