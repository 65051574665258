@import "variables";
@import "mixins";

.project_report_cards {
    margin-bottom: 20px;

    .card-project-status {
        min-height: 60px !important;
        padding: 10px 15px !important;
        box-shadow: $card_BoxShadow;
        border-radius: 0px !important;

        .card-body {
            padding: 0px !important;

            &.project-report {
                display: flex;
                justify-content: space-between;

                .card-title {
                    font-weight: $fwt700;
                }

                .card-data {
                    font-weight: $fwt600;
                }

                .card-img-part {
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
}


.reports-table {
    @include tableProperty;
}

.task-scrollbar {
    height: calc(100vh - 180px);
    margin: 10px;
}


.btn-add-reports {
    @include buttonProperty($blackColor, 30px, auto, 0, 14px, $whiteColor);
    margin-right: 10px;
    flex: none;

    &:hover {
        color: $whiteColor;
        box-shadow: none;
        background-color: $blackColor;
    }

    &:focus {
        box-shadow: none;
    }

    h6 {
        font-size: 14px;
        margin-left: 2px;
    }
}

.task-scrollbar,
.team-members-scrollbar {

    .reports-list-table,
    .team-table {

        .reports-list-thead,
        .team-members-thead {
            @include tableHeadProperty;
            z-index: 1;
        }

        .reports-list-row-data {
            .report-list-name {
                display: flex;
                align-items: center;

                .project-icon-table-row {
                    width: 25px;
                    height: 25px;
                    border-radius: 15px;
                    margin-right: 5px;
                }
            }
        }
    }
}



.multiple-images-rounded {
    display: flex;
    position: relative;
    top: 0;
    left: 0;

    .table-row-image {
        border-radius: 50px;
        border: 1px solid $whiteColor;
        background-color: $whiteColor;
        box-shadow: $card_BoxShadow;
        position: relative;

        &.members {
            width: 30px !important;
            height: 30px !important;
            position: relative;
            // top: 0;
            left: 0;
        }

        &.members1 {
            width: 30px !important;
            height: 30px !important;
            position: absolute;
            left: 20px;
        }

        &.members2 {
            width: 30px !important;
            height: 30px !important;
            position: absolute;
            left: 40px;
        }

        &.member-count {
            width: 30px !important;
            height: 30px !important;
            display: flex;
            text-align: center;
            justify-content: center;
            align-items: center;
            position: absolute;
            left: 60px;
            font-size: $fz12;
            font-weight: $fwt600;
        }
    }
}

.project_status {
    align-items: center;
    display: flex;
    justify-content: space-between;

    &.completed {
        color: $successColor;
        font-weight: $fwt600;
    }

    &.pending {
        color: $dangerColor;
        font-weight: $fwt600;
    }

    &.ongoing {
        color: $darkBlue;
        font-weight: $fwt600;
    }

    .dropdown-table-row {


        #dropdown-in-table-row {
            border: 1px solid $darkgrayColor;
            background-color: $whiteColor;
            color: $darkgrayColor;
            padding: 4px 12px;

            &:focus {
                box-shadow: none;
            }

            &::after {
                visibility: hidden;
            }
        }

        .dropdown-menu {
            min-width: 9rem !important;
            font-size: 13px !important;

            &.show {
                display: block;
                margin-top: 0px !important;
                box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.2) !important;
                border-top-style: hidden;
            }

            .dropdown-item:hover {
                color: $greenBlueColor !important;
                background-color: $whiteColor;
                border: 0.5px solid $lightGray;
            }

        }
    }
}

.project-report-by-graph {
    margin: 5px 10px;

    .project-report-head {
        display: flex;
        align-items: center;
        padding: 5px;
        margin-bottom: 20px;
    }

    .page-top-bar-dropdowns {
        display: flex;

        .btn-back {
            display: flex;
            align-items: center;
            font-size: $fz14;
            border: 1px solid $darkgrayColor;
            border-radius: 0px !important;
            margin-right: 25px;
            padding: 3px 10px;

            i {
                padding-right: 5px;
                font-size: $fz13;
            }

            &:hover {
                background-color: $darkgrayColor;
                border: 1px solid $darkgrayColor;
                color: $whiteColor;
            }
        }

        .date-picker-with-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            right: 15px;

            #from-datepicker {
                width: 110px !important;
                border: 1px solid $darkgrayColor;
                padding: 5px 10px 5px 5px !important;
                font-size: $fz12;
            }

            .date-picker-icon-part {
                background-color: $darkgrayColor;
                position: absolute;
                top: 4px;
                right: 0px;
                padding: 5px;
                width: 25px;
                height: 22px;

                .calander-icon {
                    position: absolute;
                    right: 6px;
                    top: 4px;
                    color: $whiteColor;
                }
            }

        }
    }

    .card-graph-by-phase-overall-progress {
        position: relative;

        .card.card-overall-progress {
            padding: 5px 10px;
            text-align: center;
            box-shadow: $card_BoxShadow;
            position: relative;
            min-height: 160px;
            max-height: 160px;
            min-width: auto;
            align-items: center;
            position: relative;

            .range-chart-apex {
                width: 220px;
                position: relative;
                left: 0px;
                top: -5px;
            }

            p {
                padding-top: 5px;
                // padding-bottom: 10px;
            }
        }

        .card-by-phase-report {
            padding: 10px 10px;
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            text-align: center;
            box-shadow: $card_BoxShadow;
            position: relative;
            min-height: 160px;
            max-height: 160px;
            align-items: center;
            position: relative;

            .phase-name-with-graph {
                text-align: center !important;
                max-width: 190px;
                max-height: 130px;
                position: relative;
                top: 10px;
                margin: 10px 0px 10px -5px !important;

                .phase-name {
                    font-size: $fz14;
                    color: $darkgrayColor;
                    font-weight: $fwt700;
                    position: absolute;
                    top: -5px;
                    left: 65px;
                }

                .phase-range-chart-apex {
                    width: 190px;
                    // border-right: 2px solid $lightGray;
                    // position: relative;
                    // top: -15px;
                }

                .verticle-line {
                    border-width: 1px solid gray;
                }
            }

            .add-phase-icon {
                font-size: $fz26;
                color: $darkOrangeColor;
                position: absolute;
                top: 10px;
                right: 15px;

                &:hover {
                    color: $greenBlueColor;
                }
            }
        }

        .card-project-start-date {
            padding: 15px;
            // display: flex;
            text-align: center;
            box-shadow: $card_BoxShadow;
            position: relative;
            min-height: 160px;
            max-height: 160px;
            min-width: auto;
            align-items: center;
            position: relative;

            .project-start-date-img {
                width: 80px;
                height: 80px;
                margin-bottom: 10px;
            }

            .start-name {
                font-size: $fz15;
                font-weight: $fwt700;
                color: $tableHead_text_Color;
            }

            .start-date {
                font-size: $fz13;
                font-weight: $fwt600;
                color: $darkgrayColor;
            }
        }
    }

    .project-task-status-overall-task-report {
        margin-top: 25px;

        .card-project-task-status {
            padding: 10px;
            box-shadow: $card_BoxShadow;
            // position: relative;

            .project-task-status-head {
                font-size: $fz16;
                color: $darkgrayColor;
                font-weight: $fwt700;
                margin-left: 10px;
                margin-top: 10px;
            }

            .chart-part {
                position: relative;
                display: flex !important;
                align-items: center !important;

                .project-status-chart-apex {
                    width: 400px !important;
                    position: relative;
                    left: -20px;
                }

                .total-percent {
                    position: absolute;
                    top: 80px;
                    left: 70px;
                    color: $darkgrayColor;
                    font-weight: $fwt700;
                }
            }

            .apexcharts-legend {
                position: absolute;
                top: 40px !important;
                left: auto;
                right: 0px !important;
            }

            .apexcharts-legend-series {
                margin: 9px 5px !important;
                display: flex;
                align-items: center !important;
            }

            .apexcharts-legend-text {
                font-size: $fz13 !important;
                color: $darkgrayColor !important;
                font-weight: $fwt600 !important;
                padding-left: 20px !important;
            }

            .project-summary-part {
                margin: 5px 10px;

                .project-summary-head {
                    font-size: $fz14;
                    font-weight: $fwt600;
                    color: $darkgrayColor;
                }

                .project-summary-details {
                    .summary-detailby-row {
                        font-size: $fz12;
                        // color: $darkgrayColor;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin: 15px auto;

                        &.left {
                            padding-right: 15px !important;
                            border-right: 2px solid $lightGray;
                        }

                        &.right {
                            padding-left: 5px !important;
                        }
                    }
                }
            }
        }

        .card-overall-task-report {
            padding: 15px !important;
            box-shadow: $card_BoxShadow;
            // max-height: 335px !important;
            position: relative;

            .overall-task-header-buttons {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                .overall-task-report-head {
                    font-size: $fz16;
                    color: $darkgrayColor;
                    font-weight: $fwt700;
                    margin-left: 10px;
                    margin-top: 5px;
                }
                .month-year-buttons{
                    .task-report-btn{
                        padding: 3px 8px;
                        margin: 5px;
                        font-size: $fz12;
                        background-color: $whiteColor;
                        border: 1px solid $darkgrayColor;
                        color: $darkgrayColor;
                        font-weight: $fwt500;
                        box-shadow: none;
                    }
                }
            }

            .overall-task-report-chart {
                max-width: 630px !important;
                max-height: 330px !important;
                margin-top: 5px;
                margin-bottom: 15px;
                margin-left: -5px !important;

                .task-report-chart-apex {
                    padding: 10px !important;
                }

                .apexcharts-legend {
                    bottom: 45px !important;
                    left: 26px !important;

                    .apexcharts-legend-series {
                        margin: 5px 10px !important;
                        display: flex !important;
                        align-items: center !important;

                        .apexcharts-legend-text {
                            color: $darkgrayColor !important;
                            font-size: $fz12 !important;
                            font-weight: $fwt600 !important;
                        }
                    }
                }
            }
        }
    }

    .project-budget-team-members {
        margin-top: 25px;

        .card-project-budget {
            padding: 10px;
            box-shadow: $card_BoxShadow;

            .project-budget-status {
                padding: 10px;

                .project-budget-head {
                    font-size: $fz16;
                    color: $darkgrayColor;
                    font-weight: $fwt700;
                    margin-left: 10px;
                    // margin-top: 5px;
                }

                .budget-chart-part {
                    max-width: 530px !important;
                    max-height: 630px !important;

                    .apexcharts-legend-series {
                        margin: 9px 5px !important;
                        display: flex;
                        align-items: center !important;

                        .apexcharts-legend-text {
                            color: $darkgrayColor !important;
                            font-size: $fz12 !important;
                            font-weight: $fwt600 !important;
                        }
                    }
                }
            }
        }

        .card-team-members {
            padding: 10px;
            box-shadow: $card_BoxShadow;

            .team-members-head {
                font-size: $fz16;
                color: $darkgrayColor;
                font-weight: $fwt700;
                margin-left: 10px;
                margin-top: 5px;
            }

            .team-members-table {
                margin: 15px 10px 0px 10px;

                .team-members-scrollbar {
                    height: 251px;

                    .team-member-chart {
                        display: flex;
                        align-items: center !important;
                        cursor: pointer;

                        .chat-icon {
                            background-color: $greenBlueColor;
                            border-radius: 50%;
                            padding: 3px 8px;
                            color: $whiteColor;
                            font-size: $fz16;
                            margin-right: 5px;
                        }

                        .chat-title {
                            font-size: $fz13;
                            font-weight: $fwt600;
                            color: $greenBlueColor;
                        }
                    }

                    .image-with-name-designaton {
                        display: flex;
                        align-items: center;

                        .team-member-image {
                            width: 30px;
                            height: 30px;
                            border-radius: 50%;
                            background-color: $lightGray;
                            margin-right: 10px;
                        }
                    }
                }

                .pagination>li>a {
                    padding: 5px 10px !important;
                }
            }
        }
    }
}

/* CHAT MODEL START */

.chat-model {
    .modal-content {
        width: 100% !important;
        margin-left: 0px !important;

        .add-edit-heading.chat {
            background-color: $greenBlueColor;
            padding: 10px 15px 10px 15px !important;

            .chat-with-person {
                display: flex;
                align-items: center;

                .chat-box-user-img {
                    width: 35px;
                    height: 35px;
                    background-color: $whiteColor;
                    border-radius: 50%;
                    margin-right: 10px;
                }

                p {
                    font-size: $fz15;
                    color: $whiteColor;
                    font-weight: $fwt700;
                }
            }

            .add-edit-head,
            i {
                color: $whiteColor;
            }
        }

        .chat-text-attach-smile-send {
            background-color: $whiteColor;
            padding: 15px 20px 15px 20px;
            border-top: 2px solid $grayColor;
            display: flex;
            justify-content: flex-end;

            .chat-input {
                border: none;
                box-shadow: $chatBoxShadow;
            }

            .btn-smile,
            .btn-attach,
            .btn-send {
                font-size: $fz20;
            }

            .btn-send {
                color: $darkOrangeColor;

                &:hover {
                    color: $greenBlueColor;
                }
            }
        }
    }

    .chat-box {
        height: 300px;
        max-height: 500px;
        overflow-y: scroll;

        .img-with-chat-comment {
            padding: 10px 12px;
            margin: 10px auto;
            max-width: 320px;
            box-shadow: $chatBoxShadow;
            border-radius: 10px;
            position: relative;

            .chat-box-inner-image {
                width: 35px;
                height: 35px;
                border-radius: 50%;
                // background-color: $lightYellow;
                position: relative;
                top: 5px;
            }

            &.team-member-float-left {
                display: flex;
                align-items: flex-start;
                float: left !important;

                p {
                    padding-left: 10px;
                }

                .chat-box-inner-image {
                    background-color: $lightYellow;
                }
            }

            &.user-float-right {
                display: flex;
                align-items: flex-start;
                float: right !important;
                background-color: $palelightGreen;

                p {
                    padding-left: 10px;
                }

                .chat-box-inner-image {
                    background-color: $paleGreen;
                }
            }
        }
    }
}

/* CHAT MODEL END */

/* ADD PHASES TO GRAPH MODEL START */

.graph-phase-model {
    .add-edit {
        .note {
            padding: 5px 5px;
            border-bottom: 2px solid $lightGray;
            text-align: center;
            color: $paleRed;
        }
    }

    .phase-img-name-checkbox {
        display: flex;
        align-items: center;
        padding: 15px 5px;
        justify-content: space-between;

        &:not(:last-child) {
            border-bottom: 1px solid $lightGray;
        }

        .phase-img-name {
            display: flex;
            align-items: center;

            p {
                margin-left: 10px;
            }

            .phase_image {
                width: 40px;
                height: 40px;
                border-radius: 5px;
            }
        }

        .add-phase-checkbox {
            .form-check-input[type=checkbox] {
                width: 20px;
                height: 20px;
            }
        }
    }
}

/* ADD PHASES TO GRAPH MODEL END */

.phase-details-model {
    .modal-content {
        width: 60% !important;
        left: 70px;
        top: 40px;
    }

    .phase-details {

        .phase-detail-summary,
        .phase-detail-task {
            margin-top: 10px;

            h6 {
                border-bottom: 1.5px solid $lightGray;
                padding-bottom: 5px;
                font-size: $fz15;
                font-weight: $fwt600;
                color: $tableHead_text_Color;
            }

            .summary-details,
            .task-details {
                margin: 10px auto;

                p {
                    margin: 10px auto;
                    display: flex;
                    justify-content: space-between;
                    color: $darkgrayColor;
                    font-size: $fz13;

                    .p-head {
                        font-weight: $fwt700;
                    }

                    .completed {
                        color: $greenBlueColor;
                    }
                }
            }
        }
    }
}


/* SCROLL BAR CSS with Styling START */
/* width */
.task-scrollbar::-webkit-scrollbar,
.team-members-scrollbar::-webkit-scrollbar {
    height: 5px;
    width: 5px;
}

/* Track */
.task-scrollbar::-webkit-scrollbar-track,
.team-members-scrollbar::-webkit-scrollbar-track {
    background: $scrollerBackgroundColor;
}

/* Handle */
.task-scrollbar::-webkit-scrollbar-thumb,
.team-members-scrollbar::-webkit-scrollbar-thumb {
    background: $scrollerThumbColor;
    border-radius: 0px;
}

/* Handle on hover */
.task-scrollbar:-webkit-scrollbar-thumb:hover,
.team-members-scrollbar:-webkit-scrollbar-thumb:hover {
    background: rgb(102, 102, 102);
}

/* SCROLL BAR CSS with Styling END */